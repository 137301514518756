/**
 * Created by osirvent on 01/03/2016.
 */
angular
    .module('annexa.helper',[])
    .service('HelperService',['$filter','$q', '$http', '$sce','$filter', 'Language', '$rootScope', function($filter, $q, $http, $sce,$filter, Language, $rootScope) {
        this.addAllSelectAngularTranslate = function(data, nameElement) {
            if(!nameElement) {
                nameElement = 'name';
            }

            var all = {id: '', icon:'minus', style:'black'};

            all[nameElement] =  $filter('translate')('global.literals.all');

            if(data) {
                data = $linq(data).orderBy(function(x) {
                    if(x[nameElement]) {
                        return $filter('translate')(x[nameElement]);
                    }

                    return "";
                }, this.caseInsensitiveOrNullComparer).toArray();

                data.splice(0, 0, all);
            } else {
                data = [];
                data.push(all);
            }

            return data;
        };

        this.addAllSelect = function(data, nameElement, language) {
            var all = {id: '', name: $filter('translate')('global.literals.all'), icon:'minus', style:'black', language1:$filter('translate')('global.literals.all'), language2:$filter('translate')('global.literals.all'), language3:$filter('translate')('global.literals.all')};
            if(!all.hasOwnProperty(nameElement)) {
                all[nameElement] = $filter('translate')('global.literals.all');
            }
            if(nameElement){
                all = {id: '', icon:'minus', style:'black'};
                all[nameElement] = $filter('translate')('global.literals.all');
            }
            if(data) {
                if(nameElement){
                    if(nameElement != "name") {
                        data = $linq(data).orderBy(function(x){
                            if(x[nameElement]){
                                return x[nameElement].toUpperCase()
                            }
                            return "";
                        }).toArray();
                    }else{
                        data = $linq(data).orderBy(function(x){
                            if(x['name'] && x['name'].id){
                                var lang = $filter('annexatranslate')(x.name, language);
                                if(lang) {
                                    return lang.toUpperCase();
                                }
                            }else{
                                if( x['name']){
                                    return x['name'].toUpperCase()
                                }
                            }
                            return "";
                        }).toArray();
                    }
                }
                data.splice(0, 0, all);
            }else{
                data = [];
                data.push(all);
            }
            return data;
        };

        this.addAllSelectLocalData = function (data,store_name, languageColumn, language) {
            var items = this.findFirstElementOfArrayWhere(data,'name', store_name, undefined, languageColumn, language);
            if(!items || !items.data) {
                items = [];
            }else{
                items = items.data;
            }

            return this.addAllSelect(items, languageColumn, language);
        };

        this.findFirstElementWhere = function(data,property,value, notfound) {
            var result = jQuery.grep(data,function(e) { return e[property] == value; });
            if(result.length == 0) {
                return notfound;
            }

            return result[0];
        };

        this.findFirstElementOfArrayWhere = function(data, property, value, notfound, languageColumn, language) {
            var result = notfound;
            var founded = false;

            angular.forEach(data, function(item) {
                if(!founded) {
                    if(item[property] == value) {
                        result = item;
                        founded = true;
                    }
                }
            });
            return result;
        };

        this.findElementsWhere = function(data,property,value) {
            return jQuery.grep(data,function(e) { return e[property] == value; });
        };

        this.countProperties = function(object) {
            var count=0;

            angular.forEach(object, function(element, key) {
                if(object.hasOwnProperty(key)) {
                    ++count;
                }
            });

            return count;
        };

        this.convertToArray = function(data) {
            if(!Array.isArray(data)) {
                data = [ data ];
            }

            return data;
        };

        this.fromJsonArray = function(data) {
            if(!data.status || data.status != 403) {
                var result = angular.fromJson(data);
                for (var k in result) {
                    if (result.hasOwnProperty(k)) {
                        result[k] = angular.fromJson(result[k]);
                    }
                }
            }
            return result;
        };

        this.getSelectFromLocalData = function(data, store_name, languages) {
            var items = this.findFirstElementOfArrayWhere(data,'name', store_name, undefined, undefined);
            items = items.data;

            if(items) {
                var name = { localizedTexts: [] };
                angular.forEach(languages.data, function(language) {
                    var text = { text: $filter('translate')('global.literals.all'), language: language };
                    name.localizedTexts.push(text);
                });

                var tots = {id: '', name: name, icon:'minus', style:'black'};
                items.splice(0, 0, tots);
            }

            return items;
        };

        this.getArray = function(data, property, value) {
            var result = [];
            var founded = false;

            angular.forEach(data, function(item) {
                if(!founded) {
                    if(item[property] == value) {
                        if(item.data.length > 0) {
                            result = item.data;
                        }
                        founded = true;
                    }
                }
            });
            return result;
        };
        
        this.validateDocumentFirstLetter = function(document, documentType, thirdType){
        	var valid = false;
        	var firstLetter = document.substr(0, 1).toUpperCase();
        	
        	if ("NIF" == documentType) {
        		var validFirstLetters = [];
        		
        		if ('PHISICAL' == thirdType) {
        			validFirstLetters = ["K", "L", "M"];
        		} else if ('LEGAL' == thirdType) {
        			validFirstLetters = ["A", "B", "C", "D", "E", "F", "G", "H", "J", "P", "Q", "R", "S", "U", "V", "N", "W"];    			
        		} else if ('PERSONWITHOUTLEGALPERSONALITY' == thirdType) {
        			validFirstLetters = ["E", "H", "N", "P", "S", "U", "V", "W"];
        		}
        		
        		if (validFirstLetters != []) {			
        			if (validFirstLetters.indexOf(firstLetter) >= 0) {
        				valid = true;
        			}	
        		}
        		
        	} else {
        		valid = true;
        	}
        	
        	return valid;
        }

        this.validateDocumentByType = function(document, type, thirdType){
        	var valid = false;
        	
            switch (type){
                case "DNI":
                	valid = this.validateDNI(document);
                    break;
                case "CIF":
                	valid = this.validateNIF(document);
                    break;
                case "NIE":
                	valid = this.validateNIE(document);
                    break;
                case "NIF":
                	valid = this.validateNIF(document);
                    break;
                case "PASSPORT":
                	valid = true;
                    break;
                case "OTHER":
                	valid = true;
                    break;
                default:
                	valid = false;
                    break;
            }
            
            if (valid && thirdType) {
            	valid = this.validateDocumentFirstLetter(document, type, thirdType);
            }
            
            return valid;
        };

        this.validateDNI = function(document){
            if(!document || document.length != 9){
                return false;
            }else if(isNaN(document.substr(0,document.length-1))) {
                return false;
            }else{
                var letras = 'TRWAGMYFPDXBNJZSQVHLCKET';
                var numeroDNI = document.substr(0,document.length-1);
                var letraDNI = document.substr(document.length-1,1).toUpperCase();
                if(letras.charAt(numeroDNI % 23) == letraDNI) {
                    return true;
                }else {
                    return false;
                }
            }
        };

        this.validateNIE = function(document) {
            if (!document || document.length < 9) {
                return false;
            }else if(document.substr(0,1).toUpperCase() != 'X' && document.substr(0,1).toUpperCase() != 'Y' && document.substr(0,1).toUpperCase() != 'Z') {
                return false;
            } else {
                var documentAux = document.substr(0, document.length-1);
                documentAux = documentAux.toUpperCase();
                documentAux = documentAux.replace('X', 0);
                documentAux = documentAux.replace('Y', 1);
                documentAux = documentAux.replace('Z', 2);
                documentAux = documentAux+document.substr(document.length-1,1).toUpperCase();
                return this.validateDNI(documentAux);
            }
        };

        this.validateNIF = function(document) {
            if (!document || document.length < 9) {
                return false;
            } else {            	
                var pars = 0;
                var impars = 0;
                var suma;
                var ultima;
                var unumero;
                var uletra = new Array("J", "A", "B", "C", "D", "E", "F", "G", "H", "I");
                var xxx;
                var documentAux = document.toUpperCase();
                
                if (documentAux[0] == 'K' || documentAux[0] == 'L' || documentAux[0] == 'M') {
                	var documentAux2 = documentAux.substr(1, documentAux.length);
                	documentAux2 = "0".concat(documentAux2);
                	if (this.validateDNI(documentAux2)) {
                		return true;
                	}
                }

                var regular = new RegExp(/^[ABCDEFGHJKLMNPQRSUVW]\d\d\d\d\d\d\d[0-9,A-J]$/g);
                if (!regular.exec(documentAux)){
                    return false; 
                } else {                	
                    ultima = documentAux.substr(8, 1);

                    for (var cont = 1; cont < 7; cont++) {
                        xxx = (2 * parseInt(documentAux.substr(cont++, 1))).toString() + "0";
                        impars += parseInt(xxx.substr(0, 1)) + parseInt(xxx.substr(1, 1));
                        pars += parseInt(documentAux.substr(cont, 1));
                    }
                    xxx = (2 * parseInt(documentAux.substr(cont, 1))).toString() + "0";
                    impars += parseInt(xxx.substr(0, 1)) + parseInt(xxx.substr(1, 1));

                    suma = (pars + impars).toString();
                    unumero = parseInt(suma.substr(suma.length - 1, 1));
                    unumero = (10 - unumero).toString();
                    if (unumero == 10){
                        unumero = 0;
                    }

                    if ((ultima == unumero) || (ultima == uletra[unumero])) {
                        return true;
                    }else {
                        return false;
                    }
                }
            }
        }
        this.addListMultiSelect = function(listAll, listSelected){
            var resultList = [];
            if(listAll && listSelected) {
                for (var i = 0; i < listAll.length; i++) {
                    var exist = $.grep(listSelected, function (n, j) {if (n.id == listAll[i].id) {return n;}return;});
                    if (exist != null && exist != undefined && exist.length > 0) {
                        resultList.push(listAll[i]);
                    }
                }
            }
            return resultList;
        }

        this.createTree = function(dades, lang) {
            var signActions = [];
            if (dades != null && dades != undefined) {
                var noParent = $.grep(dades, function (n, j) {if (n.parent) {return;} else {return n;}});
                if (noParent) {
                    for (var i = 0; i < noParent.length; i++) {
                        if(!noParent[i].removedDate) {
                            var signaction = {};
                            signaction.id = noParent[i].id;
                            signaction.label = $filter('annexatranslate')(noParent[i].name, lang);
                            var children = $.grep(dades, function (n, j) {
                                if (n.parent && n.parent.id == signaction.id) {
                                    return n;
                                } else {
                                    return;
                                }
                            });
                            if (children && children.length > 0) {
                                signaction.children = this.addTreeChildren(children, dades, lang);
                            }
                            signaction.template = false;
                            signActions.push(signaction);
                        }
                    }
                }
            }
            return signActions;
        };

        this.addTreeChildren = function(children, dades,lang){
            if(children && children.length > 0) {
                var signActions = [];
                for (var i = 0; i < children.length; i++) {
                    if(!children[i].removedDate) {
                        var signaction = {};
                        signaction.id = children[i].id;
                        signaction.label = $filter('annexatranslate')(children[i].name, lang);
                        var childrenSub = $.grep(dades, function (n, j) {
                            if (n.parent && n.parent.id == signaction.id) {
                                return n;
                            } else {
                                return;
                            }
                        });
                        if (childrenSub && childrenSub.length > 0) {
                            signaction.children = this.addTreeChildren(childrenSub, dades, lang);
                        }
                        signActions.push(signaction);
                    }
                }
                return signActions;
            }
            return null;
        };

        this.canEditMimeType = function(fileTypeDoc){
            var mimeTypesEditable = [
                {name:"application/msword"},
                {name:"application/rtf"},
                {name:"application/vnd.openxmlformats-officedocument.wordprocessingml.document"},
                {name:"application/vnd.ms-word.document.macroenabled.12"},
                {name:"application/vnd.openxmlformats-officedocument.wordprocessingml.template"},
                {name:"application/vnd.ms-word.template.macroenabled.12"},
                {name:"application/vnd.ms-powerpoint"},
                {name:"application/vnd.openxmlformats-officedocument.presentationml.presentation"},
                {name:"application/vnd.ms-powerpoint.presentation.macroenabled.12"},
                {name:"application/vnd.openxmlformats-officedocument.presentationml.slideshow"},
                {name:"application/vnd.ms-powerpoint.slideshow.macroenabled.12"},
                {name:"application/vnd.openxmlformats-officedocument.presentationml.template"},
                {name:"application/vnd.ms-powerpoint.template.macroenabled.12"},
                {name:"application/vnd.ms-powerpoint.addin.macroenabled.12"},
                {name:"application/vnd.openxmlformats-officedocument.presentationml.slide"},
                {name:"application/vnd.ms-powerpoint.slide.macroEnabled.12"},
                {name:"application/vnd.ms-excel"},
                {name:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},
                {name:"application/vnd.openxmlformats-officedocument.spreadsheetml.template"},
                {name:"application/vnd.ms-excel.sheet.macroenabled.12"},
                {name:"application/vnd.ms-excel.template.macroenabled.12"},
                {name:"application/vnd.ms-excel.addin.macroenabled.12"},
                {name:"application/vnd.ms-excel.sheet.binary.macroenabled.12"},
                {name:"application/vnd.oasis.opendocument.text"}
            ];
            var type = $linq(mimeTypesEditable)
                .where("x => x.name == '" + fileTypeDoc+"'")
                .toArray();
            if(type && type.length > 0){
                return true;
            }else{
                return false;
            }
        }

        this.getDocContent = function(idDoc) {
            var deferrend = $q.defer();
            if(typeof window.navigator.msSaveBlob !== 'undefined') {
                deferrend.resolve('./api/repodocs/contentpdf?id=' + idDoc);
            } else {
                $http.get('./api/repodocs/contentpdf?id=' + idDoc, {responseType: 'arraybuffer'})
                    .success(function (response) {
                        var file = new Blob([response], {type: 'application/pdf'});
                        var fileURL = URL.createObjectURL(file);
                        deferrend.resolve($sce.trustAsResourceUrl(fileURL));
                    }).catch(function (error) {
                    deferrend.reject(error);
                });
            }

            return deferrend.promise;
        }

        this.getAllName = function(user) {
            var name = user.name+' '+user.surename1 ;
            if(user.surename2){
                name = name  +' '+user.surename2 ;
            }
            return name;
        }

        this.getWorkingDays = function(stdate, endate, holidays) {
            var dateHolidays = [];
            if(holidays) {
                angular.forEach(holidays, function (value, key) {
                    var gDate = new Date(value);
                    dateHolidays.push(new Date(gDate.getFullYear(), gDate.getMonth(), gDate.getDate()));
                });
            }

            var workingdays = 0;

            var weekday = new Array(7);
            weekday[0]="Sunday";
            weekday[1]="Monday";
            weekday[2]="Tuesday";
            weekday[3]="Wednesday";
            weekday[4]="Thursday";
            weekday[5]="Friday";
            weekday[6]="Saturday";
            while(stdate <= endate) {
                var day = weekday[stdate.getDay()];

                if(day != "Saturday" && day != "Sunday") {
                    var haveHoliday = false;
                    _.forEach(dateHolidays, function(holiday){
                        if(!haveHoliday && stdate.getFullYear() == holiday.getFullYear() && stdate.getMonth() == holiday.getMonth() && stdate.getDate() == holiday.getDate()){
                            haveHoliday = true;
                        }
                    });
                    if(!haveHoliday) {
                        workingdays++;
                    }
                }
                stdate = new Date(stdate.getFullYear(), stdate.getMonth(), stdate.getDate() + 1, stdate.getHours(),stdate.getMinutes(), stdate.getSeconds());
            }

            return workingdays;
        }

        this.getWorkingDaysClass = function(days){
            if(days > 5) {
                return "success";
            } else if (days >=0 ) {
                return "amber";
            } else {
                return "danger";
            }
        }

        this.getWorkingDaysDiligenceClass = function(days){
            if(days <= 2) {
                return "success";
            } else if (days < 5) {
                return "amber";
            } else {
                return "danger";
            }
        }

        this.getExpirationDate = function(stdate, days, holidays, expireType) {
            var dateHolidays = [];
            if(holidays) {
                angular.forEach(holidays, function (value, key) {
                    var gDate = new Date(value);
                    var fixedDate = new Date(gDate.getFullYear(), gDate.getMonth(), gDate.getDate());
                    dateHolidays.push(fixedDate.getTime());
                });
            }

            var workingdays = 0;

            var weekday = new Array(7);
            weekday[0]="Sunday";
            weekday[1]="Monday";
            weekday[2]="Tuesday";
            weekday[3]="Wednesday";
            weekday[4]="Thursday";
            weekday[5]="Friday";
            weekday[6]="Saturday";

            var ctDays = 0;
            var fixed = false;

            if(days > 0) {
            	if(expireType == 'BUSINESS') {
            		while (ctDays < days) {
                        var day = weekday[stdate.getDay()];

                        if (day != "Saturday" && day != "Sunday") {
                            if (dateHolidays.indexOf(stdate.getTime()) == -1) {
                                ctDays++;
                            }
                        }

                        stdate = new Date(stdate.getFullYear(), stdate.getMonth(), stdate.getDate() + 1);
                    }
            	} else {
            		fixed = true;
            		while (ctDays < days) {
            			ctDays++;
                        stdate = new Date(stdate.getFullYear(), stdate.getMonth(), stdate.getDate() + 1);
                    }
            	} 
                
            }else{
            	if(expireType == 'BUSINESS') {
	                while ( days < ctDays) {
	                    var day = weekday[stdate.getDay()];
	
	                    if (day != "Saturday" && day != "Sunday") {
	                        if (dateHolidays.indexOf(stdate.getTime()) == -1) {
	                            ctDays--;
	                        }
	                    }
	
	                    stdate = new Date(stdate.getFullYear(), stdate.getMonth(), stdate.getDate() - 1);
	                }
            	} else {
            		fixed = true;
            		while ( days < ctDays) {
	                    ctDays--;
	                    stdate = new Date(stdate.getFullYear(), stdate.getMonth(), stdate.getDate() - 1);
	                }
            	}
            }

            while(!fixed) {
                var day = weekday[stdate.getDay()];

                if(day == "Saturday" || day == "Sunday" || dateHolidays.indexOf(stdate.getTime()) != -1) {
                    stdate = new Date(stdate.getFullYear(), stdate.getMonth(), stdate.getDate() + 1);
                } else {
                    fixed = true;
                }
            }

            return stdate;
        }

        this.validarEmail = function( email ) {
            expr = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            if ( !expr.test(email) ){
                return false;
            }else{
                return true;
            }
        }

        this.selectSelected = function(selectOptions, modelValue, modelProperty, isId, isMultiple, isBoolean){
            var objs = [];

            if(isBoolean) {

            } else {
                if (selectOptions && modelValue) {
                    if (isMultiple) {
                        angular.forEach(modelValue, function (value, key) {
                            var list = [];
                            if (isId) {
                                if (angular.isString(value)) {
                                    list = $linq(selectOptions).where("x => x.id == '" + value + "'").toArray();
                                } else {
                                    list = $linq(selectOptions).where("x => x.id == " + value).toArray();
                                }
                            } else {
                                if (value.id) {
                                    if (angular.isString(value.id)) {
                                        list = $linq(selectOptions).where("x => x.id == '" + value.id + "'").toArray();
                                    } else {
                                        list = $linq(selectOptions).where("x => x.id == " + value.id).toArray();
                                    }
                                }
                            }
                            if (list && list.length > 0) {
                                angular.forEach(list, function (value, key) {
                                    objs.push(value);
                                });
                            }
                        });
                    } else {
                        if (isId) {
                            if (angular.isString(modelValue)) {
                                objs = $linq(selectOptions).where("x => x.id == '" + modelValue + "'").toArray();
                            } else {
                                objs = $linq(selectOptions).where("x => x.id == " + modelValue).toArray();
                            }
                        } else {
                            if (modelProperty == 'language') {
                                objs = $linq(selectOptions).where("x => x.acronym == '" + modelValue.acronym + "'").toArray();
                            } else {
                                if (angular.isString(modelValue.id)) {
                                    objs = $linq(selectOptions).where("x => x.id == '" + modelValue.id + "'").toArray();
                                } else {
                                    objs = $linq(selectOptions).where("x => x.id == " + modelValue.id).toArray();
                                }
                            }
                        }
                    }
                }
            }

            return objs;
        }

        this.exportToExcel = function(list, fills, cells, fieldParent, fileName, window, titleSheet,subList,cellsSubList,auxObject,cellsAuxObject,auxObjectSubList){
            var self = this;
            var xlsFile = '<?xml version="1.0"?>\n';
            xlsFile += '<ss:Workbook xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">\n';
            xlsFile += '<ss:Worksheet ss:Name="'+$filter('translate')(titleSheet)+'">\n';
            xlsFile += '<ss:Table>\n\n';
            if(list){
                angular.forEach(list, function(value, key){
                   xlsFile += self.createRow(value, fills, cells, fieldParent,subList,cellsSubList,auxObject,cellsAuxObject,auxObjectSubList);
                })
            }
            xlsFile += '\n</ss:Table>\n';
            xlsFile += '</ss:Worksheet>\n';
            xlsFile += '</ss:Workbook>\n';
            var contentType = 'application/octet-stream';
            var file = new Blob([xlsFile], {type: contentType});
            if(window) {
                if (window.navigator.msSaveOrOpenBlob) {
                    try {
                        window.navigator.msSaveOrOpenBlob(file, fileName);
                    } catch (e) {
                        console.log(e.message);
                    }
                } else {
                    var linkElement = document.createElement('a');
                    try {
                        var url = window.URL.createObjectURL(file);
                        linkElement.setAttribute('href', url);
                        linkElement.setAttribute("download", fileName);
                        var clickEvent = new MouseEvent("click", {
                            "view": window,
                            "bubbles": true,
                            "cancelable": false
                        });
                        linkElement.dispatchEvent(clickEvent);
                    } catch (ex) {
                        console.log(ex);
                    }
                }
                return xlsFile;
            }else{
                return xlsFile;
            }

        }

        this.createRow = function(value, fills, cells, fieldParent,subList,cellsSubList,auxObject,cellsAuxObject,auxObjectSubList){
            var self = this;
            var xlsFile = '<ss:Row>\n';
            angular.forEach(cells, function(cell, keyCell){
                if(keyCell == 0) {
                    xlsFile += '  <ss:Cell>\n';
                    if (auxObject && cell == auxObject && cellsAuxObject && cellsAuxObject.length > 0) {
                        xlsFile += '    <ss:Data ss:Type="String">';
                        angular.forEach(cellsAuxObject, function (cellSubList, keyCell) {
                            if (fieldParent && value[fieldParent] && value[fieldParent][cell] && value[fieldParent][cell][cellSubList]) {
                                xlsFile += ' ' + value[fieldParent][cell].id;
                            } else if (value[cell] && value[cell][cellSubList]) {
                                xlsFile += ' ' + value[cell].id;
                            } else {
                                xlsFile += ' ';
                            }
                        });
                        xlsFile += '    </ss:Data>\n';
                    } else {
                        if (fieldParent) {
                            if (value[fieldParent] && value[fieldParent][cell]) {
                                xlsFile += '    <ss:Data ss:Type="String">' + value[fieldParent].id + '</ss:Data>\n';
                            }
                        } else if (value[cell]) {
                            xlsFile += '    <ss:Data ss:Type="String">' + value.id + '</ss:Data>\n';
                        }
                    }
                    xlsFile += '  </ss:Cell>\n';
                }
                xlsFile += '  <ss:Cell>\n';
                if(auxObject && cell == auxObject && cellsAuxObject && cellsAuxObject.length > 0){
                    xlsFile += '    <ss:Data ss:Type="String">';
                    angular.forEach(cellsAuxObject, function (cellSubList, keyCell) {
                        if(fieldParent && value[fieldParent] && value[fieldParent][cell] && value[fieldParent][cell][cellSubList]){
                            xlsFile += ' ' +value[fieldParent][cell][cellSubList];
                        }else if(value[cell] && value[cell][cellSubList]){
                            xlsFile += ' ' + value[cell][cellSubList];
                        }else{
                            xlsFile += ' ';
                        }
                    });
                    xlsFile += '    </ss:Data>\n';
                }else {
                    if (fieldParent) {
                        if (value[fieldParent] && value[fieldParent][cell]) {
                            xlsFile += '    <ss:Data ss:Type="String">'+ value[fieldParent][cell] + '</ss:Data>\n';
                        }
                    } else if (value[cell]) {
                        xlsFile += '    <ss:Data ss:Type="String">' + value[cell] + '</ss:Data>\n';
                    }
                }
                xlsFile += '  </ss:Cell>\n';
            });
            xlsFile += '</ss:Row>\n';
            if(subList && cellsSubList && cellsSubList.length > 0){
                if(fieldParent && value[fieldParent] && value[fieldParent][subList]){
                    angular.forEach(value[fieldParent][subList], function(subObject, keySubObject) {
                        xlsFile += '  <ss:Row>\n';
                        xlsFile += '  <ss:Cell>\n';
                        xlsFile += '  </ss:Cell>\n';
                        xlsFile += '  <ss:Cell>\n';
                        xlsFile += '    <ss:Data ss:Type="String">';
                        angular.forEach(cellsSubList, function (cellSubList, keyCell) {
                            if(auxObjectSubList){
                                if(subObject[auxObjectSubList] && subObject[auxObjectSubList][cellSubList]) {
                                    xlsFile += " " + subObject[auxObjectSubList][cellSubList];
                                }else{
                                    xlsFile += ' ';
                                }
                            }else {
                               if(subObject[cellSubList]){
                                   xlsFile += " " + subObject[cellSubList];
                               }else{
                                   xlsFile += ' ';
                               }
                            }
                        });
                        xlsFile += '    </ss:Data>\n';
                        xlsFile += '  </ss:Cell>\n';
                        xlsFile += '  </ss:Row>\n';
                    });
                }else if(value[subList]){
                    angular.forEach(value[subList], function(subObject, keySubObject) {
                        xlsFile += '  <ss:Row>\n';
                        xlsFile += '  <ss:Cell>\n';
                        xlsFile += '  </ss:Cell>\n';
                        xlsFile += '  <ss:Cell>\n';
                        xlsFile += '    <ss:Data ss:Type="String">';
                        angular.forEach(cellsSubList, function (cellSubList, keyCell) {
                           if(auxObjectSubList){
                                if(subObject[auxObjectSubList] && subObject[auxObjectSubList][cellSubList]) {
                                    xlsFile += " " + subObject[auxObjectSubList][cellSubList];
                                }else{
                                    xlsFile += ' ';
                                }
                           }else {
                               if (subObject[cellSubList]) {
                                   xlsFile += " " + subObject[cellSubList];
                               } else {
                                   xlsFile += ' ';

                               }
                           }
                        });
                        xlsFile += '    </ss:Data>\n';
                        xlsFile += '  </ss:Cell>\n';
                        xlsFile += '  </ss:Row>\n';
                    });
                }
            }
            if(fills && value[fills] && value[fills].length > 0) {
                angular.forEach(value[fills], function(fill, keyFill){
                    xlsFile += self.createRow(fill, fills, cells, fieldParent,subList,cellsSubList,auxObject,cellsAuxObject,auxObjectSubList)
                })
            }
            return xlsFile;
        }
        
        this.diffDates = function (start, end, literalDays) {
            var duration = Math.abs(end - start);

            var milliseconds = parseInt((duration%1000)/100)
                , seconds = parseInt((duration/1000)%60)
                , minutes = parseInt((duration/(1000*60))%60)
                , hours = parseInt((duration/(1000*60*60))%24)
                , days = parseInt((duration/(1000*60*60*24)));

            hours = (hours < 10) ? "0" + hours : hours;
            minutes = (minutes < 10) ? "0" + minutes : minutes;
            seconds = (seconds < 10) ? "0" + seconds : seconds;

            if(literalDays && days > 0) {
                return days + " " + $filter('translate')(literalDays) + " " + hours + ":" + minutes + ":" + seconds
            } else {
                return hours + ":" + minutes + ":" + seconds
            }
        }

        this.diffDateDays = function(start, end) {
            var oneDay = 24*60*60*1000;

            return Math.round(Math.abs((start - end)/(oneDay)));
        }

        this.treeSelected = function(value) {
            return value && value.model && value.model.$selected && value.model.$selected.id;
        }

        this.getDefultProfileFor = function(entityType, module, profilesUser, userDefaultProfiles, extra) {
            if(extra && extra.hasOwnProperty('@id')) {
                extra = JSOG.decode(extra);
            }

            if(profilesUser.length == 1) {
                return profilesUser[0].id;
            } else {
                var defaultProfile = undefined;

                if(userDefaultProfiles) {
                    var profiles = $linq(userDefaultProfiles).where("x => x.entityType == '" + entityType + "'").select("x => x.profile").toArray();

                    if (profiles && profiles.length > 0) {
                        defaultProfile = profiles[0];
                    }
                }

                if(module != 'EXPED') {
                    if(defaultProfile) {
                        return defaultProfile.id;
                    }
                } else {
                    if(extra.processProfiles.length == 1) {
                        return extra.processProfiles[0].profile.id;
                    } else {
                        if(defaultProfile && $linq($linq(extra.processProfiles).select("x => x.profile").toArray()).count("x => x.id == " + defaultProfile.id) > 0) {
                            return defaultProfile.id;
                        }
                    }
                }
            }

            return undefined;
        }

        this.getOneProfileFor = function(entityType, module, profilesUser, userDefaultProfiles, extra) {
            if(extra && extra.hasOwnProperty('@id')) {
                extra = JSOG.decode(extra);
            }
            
            if(profilesUser.length == 1) {
                return true;
            } else if(module == 'EXPED') {
                var defaultProfile = undefined;

                if(userDefaultProfiles) {
                    var profiles = $linq(userDefaultProfiles).where("x => x.entityType == '" + entityType + "'").select("x => x.profile").toArray();

                    if (profiles && profiles.length > 0) {
                        defaultProfile = profiles[0];
                    }
                }

                if(extra.processProfiles.length == 1) {
                    return true;
                } else {
                    if(defaultProfile && $linq($linq(extra.processProfiles).select("x => x.profile").toArray()).count("x => x.id == " + defaultProfile.id) > 0) {
                        return true;
                    }
                }
            }

            return false;
        }
        
        this.caseInsensitiveOrNullComparer = function (x, y)
        {
            if(x && y){
	        	var tempX = ((typeof x === 'string' || x instanceof String) ? x.toLowerCase() : x);
	            var tempY = ((typeof y === 'string' || y instanceof String) ? y.toLowerCase() : y);
	
	            return (tempX < tempY ? -1 : tempX > tempY ? 1 : 0);
            }else if(x && !y){
            	return -1;
            }else if(!x && y){
            	return 1;
            }else{
            	return 0;
            }
        };
        
        this.sortArrayByLanguage = function (array) {
            var sortedArray = array.sort(function (a, b) {
                if(Language.getActiveColumn() == 'language1') {
                    return a.language1.localeCompare(b.language1);
                } else if(Language.getActiveColumn() == 'language2') {
                    return a.language2.localeCompare(b.language2);
                } else if(Language.getActiveColumn() == 'language3') {
                    return a.language3.localeCompare(b.language3);
                }
            });
            return sortedArray;
        }

		this.getPropertiesInPattern = function ()
        {
			var properties = {};
            if($rootScope && $rootScope.app &&  $rootScope.app.configuration &&  $rootScope.app.configuration.document_name_pattern && $rootScope.app.configuration.document_name_pattern.value){
				var pos = $rootScope.app.configuration.document_name_pattern.value.indexOf('[acronym]');
                if (pos >= 0) {
	    			properties.acronym = {name: '[acronym]', exist: true, pos:pos};
				}
				var pos = $rootScope.app.configuration.document_name_pattern.value.indexOf('[name]');
                if (pos >= 0) {
                	properties.name = {name: '[name]', exist: true, pos:pos};
				}
				var pos = $rootScope.app.configuration.document_name_pattern.value.indexOf('[date-');
                if (pos >= 0) {
					var date = $rootScope.app.configuration.document_name_pattern.value.substring(pos, $rootScope.app.configuration.document_name_pattern.value.length);
					if(date && date.indexOf(']') >= 0){
						var dateAux = date.substring(0, date.indexOf(']'))
	                	properties.date = {name: date.substring(0, (date.indexOf(']')+1)), exist: true, pos:pos, datePattern:dateAux.replace('[date-', '')};
					}else{
						properties.date = {name: date, exist: true, pos:pos, datePattern:'ddMMyyyy'};
					}	
					            		
				}
            }
			return properties;
			
        };

		this.getFormattedPattern = function (acronym, dateName, formatDate)
        {
			var pattern = '';
            if($rootScope && $rootScope.app &&  $rootScope.app.configuration &&  $rootScope.app.configuration.document_name_pattern && $rootScope.app.configuration.document_name_pattern.value){
				pattern = angular.copy($rootScope.app.configuration.document_name_pattern.value);
				pattern = pattern.replaceAll('[acronym]', acronym);
				pattern = pattern.replaceAll('[name]', '[\\s\\S]*');
				var datePattern = '';
				if(formatDate){
					datePattern = formatDate.replaceAll('/','[/]');
					datePattern = datePattern.replaceAll('d','\\d');
					datePattern = datePattern.replaceAll('D','\\d');
					datePattern = datePattern.replaceAll('M','\\d');
					datePattern = datePattern.replaceAll('m','\\d');
					datePattern = datePattern.replaceAll('y','\\d');
					datePattern = datePattern.replaceAll('Y','\\d');
					datePattern = datePattern.replaceAll('H','\\d');
					datePattern = datePattern.replaceAll('h','\\d');
					datePattern = datePattern.replaceAll('s','\\d');
					datePattern = datePattern.replaceAll('S','\\d');
				}
				if(dateName){
					pattern = pattern.replaceAll(dateName, datePattern);
				}
				pattern = '^'+pattern+'$';
            }else{
				pattern = '^[\\s\\S]*$';
			}
			return pattern;
			
        };

		this.validateDatePattern = function(dateName, formatDate, title){
			var datePattern = '';
			var whereIsData = '';
			if(dateName && $rootScope && $rootScope.app &&  $rootScope.app.configuration &&  $rootScope.app.configuration.document_name_pattern && $rootScope.app.configuration.document_name_pattern.value){
				var pattern = angular.copy($rootScope.app.configuration.document_name_pattern.value);
				if(pattern.trim().startsWith(dateName)){
					whereIsData = 'start';
				}else if(pattern.trim().endsWith(dateName)){
					whereIsData = 'end';
				}
			}
			if(formatDate){
				datePattern = formatDate.replaceAll('/','[/]');
				datePattern = datePattern.replaceAll('d','\\d');
				datePattern = datePattern.replaceAll('D','\\d');
				datePattern = datePattern.replaceAll('M','\\d');
				datePattern = datePattern.replaceAll('m','\\d');
				datePattern = datePattern.replaceAll('y','\\d');
				datePattern = datePattern.replaceAll('Y','\\d');
				datePattern = datePattern.replaceAll('H','\\d');
				datePattern = datePattern.replaceAll('h','\\d');
				datePattern = datePattern.replaceAll('s','\\d');
				datePattern = datePattern.replaceAll('S','\\d');
			}
			try{
				var matchPattern = title.match(datePattern); 
				if(matchPattern && matchPattern.index >= 0){
					if(whereIsData == 'start'){
						var date = title.substring(matchPattern.index,matchPattern.index+formatDate.length);
						return validateDate(date,formatDate);
					}else if(whereIsData == 'end'){
						var titletrim = title.trim();
						var date = titletrim.substring((titletrim.length - formatDate.length),titletrim.length)
						return validateDate(date,formatDate);
					}else{
						var valid = false;
						var titleName = getNextMatch(title, datePattern);
						if(titleName){
							while(!valid && titleName){
								valid = validateDate(titleName.substring(0,formatDate.length),formatDate);
								titleName = getNextMatch(titleName.substring(1, titleName.length), datePattern);
							}
							
						}
						return valid;
					}
				}else{
					return false;
				}
			}catch(e){
				return false;
			}
		};
		
		var getNextMatch = function(title, datePattern){
			if(title){
				var matchPattern = title.match(datePattern); 
				if(matchPattern){
					return title.substring(matchPattern.index,title.length);	
				}else{
					return '';
				}
			} else{
				return '';
			}
		};
		
		var validateDate = function(title,formatDate){
            if(title && formatDate){
				var datePattern = '';
					if(formatDate){
					datePattern = formatDate.replaceAll('d','D');
					datePattern = datePattern.replaceAll('y','Y');
					datePattern = datePattern.replaceAll('h','H');
					datePattern = datePattern.replaceAll('S','s');
				}
				return moment(title, datePattern,true).isValid();
			}else{
				return false;
			}
		}
		
		this.getErrorMessageValidatePattern = function(){
			var errorMessage = $filter('translate')('global.errors.docNameFormat');
			var propertiesPattern = this.getPropertiesInPattern();
			if(propertiesPattern){
			 	if($rootScope && $rootScope.app &&  $rootScope.app.configuration &&  $rootScope.app.configuration.document_name_pattern && $rootScope.app.configuration.document_name_pattern.value){
					var propErrors = angular.copy($rootScope.app.configuration.document_name_pattern.value);
					if(propertiesPattern.acronym && propertiesPattern.acronym.name){
						propErrors = propErrors.replace(propertiesPattern.acronym.name,$filter('translate')('global.errors.docName.acronym'));
					}
					if(propertiesPattern.name && propertiesPattern.name.name){
						propErrors = propErrors.replace(propertiesPattern.name.name,$filter('translate')('global.errors.docName.name'));
					}
					if(propertiesPattern.date && propertiesPattern.date.name){
						propErrors = propErrors.replace(propertiesPattern.date.name,$filter('translate')('global.errors.docName.date'));
						propErrors = propErrors.replace('--pattern--',propertiesPattern.date.datePattern);
					}
					errorMessage = errorMessage + ' (' + propErrors+')';
				}
			}
			return errorMessage;
		}
		this.getPrefilter= function(module, table, id, list, property, fromDashboard, fromDashboardValue){
			if(fromDashboard === true){
				return fromDashboardValue;
			}else if(module && table && id){
				if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.filters_configuration && $rootScope.app.configuration.filters_configuration.filtersConfiguration && $rootScope.app.configuration.filters_configuration.filtersConfiguration.modules){
					var moduleConf = $linq($rootScope.app.configuration.filters_configuration.filtersConfiguration.modules).firstOrDefault(undefined, "x => x.name == '"+module+"'");
					if(moduleConf && moduleConf.filters && moduleConf.filters.length > 0){
						var tableConf = $linq(moduleConf.filters).firstOrDefault(undefined, "x => x.table == '"+table+"'");
						if(tableConf && tableConf.filters && tableConf.filters.length > 0){
							var filterConf = $linq(tableConf.filters).firstOrDefault(undefined, "x => x.name == '"+id+"'");
							if(filterConf && filterConf.value){
								if(filterConf.factoryArray && filterConf.nameArray && filterConf.literalArray){
									if(list && list.length > 0 && property){
										if(filterConf.type == 'select-multiple'){
											try{
												var selectedList = filterConf.value.split(';');
												if(selectedList && selectedList.length > 0){
													return $linq(list).intersect(selectedList, function(x,y){
														if(y && (y[property] || y[property] === false) && (y[property]+"") == x ){
															return true;
														}else{
															return false;
														}
													}).toArray(); 
												}
											}catch(e){}
										}else{
											var item =  $linq(list).firstOrDefault(undefined, function(x){
												if(x && (x[property] || x[property] === false) && (x[property]+"") == filterConf.value ){
													return true;
												}else{
													return false;
												}
											});		
											if(item && (item[property] || item[property] === false)){
												return item[property];
											}								
										}
									}
								}else{
									if(filterConf.type == 'dateRange'){
										if(filterConf.value){
											var dates = {};
											try{
												var datesAux = filterConf.value.split(';');
												if(datesAux && datesAux.length > 1 && datesAux[1]) {
													if(datesAux[0] == 'dataFrom'){
														dates.dataFrom = datesAux[1];
													}else{
														dates.dataTo = datesAux[1];
													}
												}
												if(datesAux && datesAux.length > 3 && datesAux[3]) {
													dates.dataTo = datesAux[3];
												}
												return dates;
											}catch(e){}
										}
									}else if(filterConf.type == 'checkbox'){
										return ((filterConf.value == 'true')?true:false);
									}else{
										return filterConf.value;
									}
								}
							}
						}
					}
				}
			}
			return undefined;
		}
		
		this.getPrefilterType= function(filter, predefinedFilter, property){
			if(filter && predefinedFilter){
				if(filter.data){
					if(filter.data.length > 0 && property){
						if(filter.type == 'select-multiple'){
							try{
								var selectedList = predefinedFilter.value.split(';');
								if(selectedList && selectedList.length > 0){
									return $linq(filter.data).intersect(selectedList, function(x,y){
										if(y && (y[property] || y[property] === false) && (y[property]+"") == x ){
											return true;
										}else{
											return false;
										}
									}).toArray(); 
								}
							}catch(e){}
						}else{
							var item =  $linq(filter.data).firstOrDefault(undefined, function(x){
								if(x && (x[property] || x[property] === false) && (x[property]+"") == predefinedFilter.value ){
									return true;
								}else{
									return false;
								}
							});		
							if(item && (item[property] || item[property] === false)){
								return item[property];
							}								
						}
					}
				}else{
					if(filter.type == 'dateRange'){
						if(predefinedFilter.value){
							var dates = {};
							try{
								var datesAux = predefinedFilter.value.split(';');
								if(datesAux && datesAux.length > 1 && datesAux[1]) {
									if(datesAux[0] == 'dataFrom'){
										dates.dataFrom = datesAux[1];
									}else{
										dates.dataTo = datesAux[1];
									}
								}
								if(datesAux && datesAux.length > 3 && datesAux[3]) {
									dates.dataTo = datesAux[3];
								}
								return dates;
							}catch(e){}
						}
					}else if(filter.type == 'checkbox'){
						return ((predefinedFilter.value == 'true')?true:false);
					}else{
						return predefinedFilter.value;
					}
				}
			}
			return undefined;
		}
		
		this.getPrefilterNegated = function(module, table, id, fromDashboard){
			if(fromDashboard === true){
				return false
			}else if(module && table && id){
				if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.filters_configuration && $rootScope.app.configuration.filters_configuration.filtersConfiguration && $rootScope.app.configuration.filters_configuration.filtersConfiguration.modules){
					var moduleConf = $linq($rootScope.app.configuration.filters_configuration.filtersConfiguration.modules).firstOrDefault(undefined, "x => x.name == '"+module+"'");
					if(moduleConf && moduleConf.filters && moduleConf.filters.length > 0){
						var tableConf = $linq(moduleConf.filters).firstOrDefault(undefined, "x => x.table == '"+table+"'");
						if(tableConf && tableConf.filters && tableConf.filters.length > 0){
							var filterConf = $linq(tableConf.filters).firstOrDefault(undefined, "x => x.name == '"+id+"'");
							if(filterConf && filterConf.negated && filterConf.negated === 'true'){
								return true;
							}
						}
					}
				}
			}
			return false;
		}
		
		this.getPrefilterNegatedType = function(filter, predefinedFilter){
			if(filter && predefinedFilter){
				if(filter.type !== 'checkbox'){
					return ((predefinedFilter.negated == 'true')?true:false);
				}
			}
			return false;
		}
    }]);