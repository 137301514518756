/**
 * Created by osirvent on 04/08/2016.
 */
angular
    .module('annexaApp')
    .factory('BoxFactory',['RestService', 'globalModals', 'AnnexaFormlyFactory', 'AnnexaFormsFactory', 'AnnexaModalFactory', 'TableFilter', 'Language', '$filter', 'DTColumnBuilder', '$rootScope', 'TaskFactory','HelperService', 'ErrorFactory', '$compile', 'WizardHandler', 'DccumentsFactory', 'RegFactory', 'GlobalDataFactory', 'TramFactory', 'DialogsFactory', 
		function (RestService, globalModals, AnnexaFormlyFactory, AnnexaFormsFactory, AnnexaModalFactory, TableFilter, Language, $filter, DTColumnBuilder, $rootScope, TaskFactory, HelperService,ErrorFactory, $compile, WizardHandler, DccumentsFactory, RegFactory, GlobalDataFactory, TramFactory, DialogsFactory) {

        var lan = Language.getActiveColumn();

        var row1 = {"id": "PHISICAL"};
        var row2 = {"id": "LEGAL"};
        var row3 = {"id": "PERSONWITHOUTLEGALPERSONALITY"};
        row1[lan] = $filter('translate')('global.thirds.literals.phisical');
        row2[lan] = $filter('translate')('global.thirds.literals.legal');
        row3[lan] = $filter('translate')('global.thirds.literals.personwithoutlegalpersonality');
        var thirdTypes = [row1, row2, row3];


        var row1 = {"id": "DNI"};
        var row2 = {"id": "CIF"};
        var row3 = {"id": "NIE"};
        var row4 = {"id": "NIF"};
        var row5 = {"id": "PASSPORT"};
        var row6 = {"id": "OTHER"};
        row1[lan] = "DNI";
        row2[lan] = "CIF";
        row3[lan] = "NIE";
        row4[lan] = "NIF";
        row5[lan] = $filter('translate')('global.thirds.literals.passport');
        row6[lan] = $filter('translate')('global.literals.other');
        var thirdIdentificationDocumentTypes = [row1, row2, row3, row4, row5, row6];
        var openClosed = [{"id":"0"}, {"id":"1"}, {"id":"2"}];
        openClosed[0][lan] = $filter('translate')('global.literals.open_closed');
        openClosed[1][lan] = $filter('translate')('global.literals.openedM');
        openClosed[2][lan] = $filter('translate')('global.literals.closedM');
        var statesOutput = [{id:1, name:'global.reg.literals.managed'},{id:2, name:'global.reg.literals.noManaged'}];

        var renderRadioColumn = function(data, type, full, meta){
            return '<input type="radio" id="'+full.id+'" name ="selected" data-ng-model="radioSelected" data-ng-value="'+ full.id +'" aria-label="{{ \'global.literals.select\' | translate }}">';
        };

        // expFilterAdv
        var checkboxColumn = function (data, type, full, meta) {
            return '<input type="checkbox" id="'+data.id+'" name="selected" data-ng-model="rowSelected['+full.id+']" '+
                        'data-ng-change="selectRow('+data.id+')" aria-label="{{ \'global.literals.select\' | translate }}">';
        }
        
        var checkboxColumnTitle = function(a,b,c,d) {
            return  '<input type="checkbox" id="annexaAdvancedSearchAddAllElements" name="annexaAdvancedSearchAddAllElements" ng-model="selectAll" ng-change="selectAllRows()">';
        }
        
        return {
            DossierBox: {
                initialize: function(title_box, placeholder_search, advanced_search_title, newButton, searchButton, advancedSearchButton, searchAddButton, EntityFactory, noShowState, additionalFilter, added_filter, onlyOne) {

                    var DaysColumn          = new RemaingDaysColumn($filter, 'global.literals.days', HelperService);
                    var ProcedureColumn     = new DatabaseTranslatedColumn($filter, 'global.literals.procedure', lan);
                    var TransactionColumn   = new DatabaseTranslatedColumn($filter, 'global.literals.transaction', lan);
                    var StDateColumn        = new StartDateColumn($filter, 'global.delegate.startDate');
                    var CreatorUserColumn   = new UserColumn($filter, 'global.literals.creator');
                    var RelatedColumn       = new RelatedDossiersColumn();

                    var tfilterDossier  = new TableFilter();
                    var tdDefDossier    = new TableData('tram', 'Dossier', './api/tram/byfilter').setSortName('maxDays').setSort([[1, 'asc']]);

                    /*************************************/
                    /* Definir filtre per cerca avançada */
                    /*************************************/
                    tfilterDossier.addElement('dossierNumber', 'text', 0, 'global.literals.code').setFilterCallAux(true);
                    tfilterDossier.addElement('family', 'select', 1, 'global.literals.family', 2, 'FamilyProcedure', true, lan, false, undefined, lan).setFilterCallAux(true);
                    tfilterDossier.addElement('procedure', 'text', 2, 'global.literals.procedure').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('subject', 'text', 3, 'global.literals.subject').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('thirds', 'text', 4, 'global.literals.thirdsInt').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('thirds_identification', 'text', 5, 'global.literals.thirds_identification').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('tramitation', 'text', 6, 'global.literals.transaction').setFilterCallAux(true).setUserChosenFilter(true);
                    if(!noShowState){
                        tfilterDossier.addElement('openDossierTransaction', 'select', 7, 'global.tram.literals.dossier_transactions_status', 3, angular.copy(openClosed), true, 'name', false).setFilterCallAux(true).setUserChosenFilter(true);
                    }
                    tfilterDossier.addElement('custom_fields_search', 'text', 8, 'global.literals.customFields').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('dates', 'dateRange', 9, 'global.delegate.startDate').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('creator', 'text', 10, 'global.literals.creator').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('register_entry', 'text', 11, 'global.literals.register_entry_INI').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.loadLocalData([],undefined, undefined, lan);

                    var tabledataColumnsDossier = [];
                    	if(onlyOne){
                    		tabledataColumnsDossier.push(DTColumnBuilder.newColumn(null).renderWith(renderRadioColumn).notSortable());
                    	}else{
                    		tabledataColumnsDossier.push(DTColumnBuilder.newColumn(null).renderWith(function(data, type, full, meta){
	                        	// expFilterAdv
	                            return '<input type="checkbox" id="'+full.id+'" name="selected" data-ng-model="rowSelected['+full.id+']" data-ng-change="selectRow('+data.id+')" aria-label="{{ \'global.literals.select\' | translate }}">';
	                        }).withTitle(checkboxColumnTitle()).notSortable());
                    	}
                        tabledataColumnsDossier.push(DTColumnBuilder.newColumn('dossierNumber').withTitle($filter('translate')('global.literals.code')));
						tabledataColumnsDossier.push(DTColumnBuilder.newColumn('procedure.' + ProcedureColumn.getColumn()).withTitle(ProcedureColumn.getTitle()));
                        tabledataColumnsDossier.push(DTColumnBuilder.newColumn('subject').withTitle($filter('translate')('global.literals.dossier')));

                    this.boxDefinition = new BoxDefinition(title_box);
                    this.boxDefinition.addViewAndNewPremissions('view_dossier', 'start_dossier');
                    if(newButton) {
                        this.boxDefinition.addNew(this.newDossier, this.completeNewDossier);
                    }
                    
                    if(added_filter){
                    	this.boxDefinition.isRelatedDossier = true;
                    }
                    if(searchButton) {
                        this.boxDefinition.addSearch('tram','Dossier', placeholder_search, this.processSearchDossier, RestService, '{ "subject": "@VAL@", "dossierNumber": "@VAL@" }', added_filter, additionalFilter);
                    }
                    if(advancedSearchButton) {

                        if(this.boxDefinition.searchAdd){
                        	var modalName = angular.copy(globalModals.relatedDossierRelation);
                            this.boxDefinition.addSearchAdd(((modalName && this.boxDefinition.isRelatedDossier)? modalName: undefined), this.processModalBodyAddDossier, this.completeNewDossier, AnnexaFormlyFactory);
                        }

                        // Definir modal cerca avançada
                        this.boxDefinition.addAdvandedSearch(
                            new BoxAdvancedSearchModalDefinition('dossierbox', advanced_search_title, tfilterDossier, tdDefDossier, tabledataColumnsDossier, this.boxDefinition).changeSize('modal-lg'),
                            AnnexaModalFactory, true, added_filter, additionalFilter
                        );
                        if(this.boxDefinition.advancedSearchModalDefinition) {
                            this.boxDefinition.advancedSearchModalDefinition.addFilterCallAux = function (filterCall, filterCallAux) {
                                if (filterCallAux) {
                                    filterCallAux.filterOrigin = 'dossierAdvancedView';
                                }
                            }
                        }

                    }
                    if(!newButton && !searchButton && !advancedSearchButton && !searchAddButton){
                        this.boxDefinition.disabled = true;
                    }

                    return this;
                },
                boxDefinition: null,
                newDossier: function () {
                    alert("Nou expedient");
                },
                completeNewDossier: function (definition) {
                	if(definition.annexaFormly){
                		var relation = ((definition.annexaFormly.model && definition.annexaFormly.model)?definition.annexaFormly.model.modal_body.relation:undefined);
	                    var newContent = [];
	                    if(definition.extra.searchedValue && definition.extra.searchedValue.object && Array.isArray(definition.extra.searchedValue.object)){
	                		_.forEach(definition.extra.searchedValue.object, function(obj){
	                			newContent.push(JSOG.decode(obj));
	                		});
	                	}else{
	                		newContent.push(JSOG.decode(definition.extra.searchedValue.object));
	                	}
	                    
	                    // Completar cerca simple
	                    if (definition.extra.content){
	                    	_.forEach(newContent, function(obj){
	                    		if(definition.extra.isRelatedDossier){
	                    			definition.extra.content.push({related:obj, relatedDossierRelation:relation, inverse: false});
	                    		}else{
	                    			definition.extra.content.push(obj);
	                    		}
	                    	});
	                        if(definition.extra.saveSearch) {
	                        	definition.extra.saveSearch(newContent, relation);
	                        }
	                    }
	                    // Completar cerca avançada
	                    if (definition.extra.modal && definition.extra.modal.boxDefinition && definition.extra.modal.boxDefinition.content){
	                    	_.forEach(newContent, function(obj){
	                    		if(definition.extra.modal.boxDefinition.isRelatedDossier){
	                    			definition.extra.modal.boxDefinition.content.push({related:obj, relatedDossierRelation:relation, inverse: false});
	                    		}else{
	                    			definition.extra.modal.boxDefinition.content.push(obj);
	                    		}
	                        });
	                        if(definition.extra.modal.boxDefinition.saveSearch) {
	                        	definition.extra.modal.boxDefinition.saveSearch(newContent, relation);
	                        }
	                        definition.extra.modal.close();
	                        if(definition.extra.close) {
	                            definition.extra.close();
	                        }
	                    }
	
	                    definition.extra.searchedValue = { id: -1, value: ''};
	                    definition.close();
                	}else{
	                    var newContent = [];
	                    if(definition.searchedValue && definition.searchedValue.object && Array.isArray(definition.searchedValue.object)){
	                		_.forEach(definition.searchedValue.object, function(obj){
	                			newContent.push(JSOG.decode(obj));
	                		});
	                	}else{
	                		newContent.push(JSOG.decode(definition.searchedValue.object));
	                	}
	                    
	                    // Completar cerca simple
	                    if (definition.content){
	                    	_.forEach(newContent, function(obj){
	                    		if(definition.isRelatedDossier){
	                    			definition.content.push({related:obj});
	                    		}else{
	                    			definition.content.push(obj);
	                    		}
	                    	});
	                        if(definition.saveSearch) {
	                            definition.saveSearch(newContent);
	                        }
	                    }
	                    // Completar cerca avançada
	                    if (definition.modal && definition.modal.boxDefinition && definition.modal.boxDefinition.content){
	                    	_.forEach(newContent, function(obj){
	                    		if(definition.modal.boxDefinition.isRelatedDossier){
	                    			definition.modal.boxDefinition.content.push({related:obj});
	                    		}else{
	                    			definition.modal.boxDefinition.content.push(obj);
	                    		}
	                        });
	                        if(definition.modal.boxDefinition.saveSearch) {
	                            definition.modal.boxDefinition.saveSearch(newContent);
	                        }
	                        definition.modal.close();
	                        if(definition.close) {
	                            definition.close();
	                        }
	                    }
	
	                    definition.searchedValue = { id: -1, value: ''};
                	}
                },
                processSearchDossier: function (object) {
                    return object.dossierNumber + " (" + object.subject + ")";
                },
                processModalBodyAddDossier: function(selected) {
                    return { relateds: selected, relation: undefined };
                }
            },
            ThirdBox: {
                initialize: function(title_box, placeholder_search, advanced_search_title, newButton, searchButton, advancedSearchButton, searchAddButton, EntityFactory, thirdBoxType, added_filter, additionalFilter, renderType) {

                    var tfilterThird = new TableFilter(true);
                    tfilterThird.addElement('identificationDocument', 'text', 0, 'global.literals.identifier');
                    tfilterThird.addElement('name', 'text', 1, 'global.literals.name');
                    tfilterThird.addElement('identificationReference', 'text', 2, 'global.thirds.literals.identificationReference');
                    tfilterThird.addElement('onlyActive', 'checkbox', 3, 'global.thirds.literals.onlyActive').setFilterCallAux(true).setDefaultValue(true);
                    tfilterThird.addElement('city', 'text', 4, 'global.literals.city').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterThird.addElement('mail', 'text', 5, 'global.profile.email').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterThird.addElement('phone', 'text', 6, 'global.profile.phone').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterThird.addElement('identificationDocumentType', 'select', 7, 'global.thirds.literals.identificationdocumenttype', 3, HelperService.addAllSelect(angular.copy(thirdIdentificationDocumentTypes), 'name', $rootScope.app.language), true, 'name', false).setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterThird.addElement('thirdType', 'select', 8, 'global.literals.type', 3, HelperService.addAllSelect(angular.copy(thirdTypes), 'name', lan), true, 'name', false).setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterThird.addElement('dataThird', 'dateRange', 9, 'global.thirds.literals.dateCreated').setFilterCallAux(true).setUserChosenFilter(true);

                    if(additionalFilter) {
                    	additionalFilter.active = true;
                    } else {
                    	additionalFilter = {active: true};
                    }

                    // Format columna 'Tipus tercer' (icona)
                    var thirdTypeColumn = new IconFilterColumn($rootScope, 'filterData', 'thirdType', [
                            new IconSearchOption('all', '', 'fa-minus fa-stack-1x', 'fa-minus', 'global.literals.all'),
                            new IconSearchOption('PHISICAL','PHISICAL', 'fa-user fa-lg ico-third', 'fa-user fa-fw', 'global.thirds.literals.phisical'),
                            new IconSearchOption('LEGAL','LEGAL', 'fa-bank fa-lg ico-third', 'fa-bank fa-fw', 'global.thirds.literals.legal'),
                            new IconSearchOption('PERSONWITHOUTLEGALPERSONALITY','PERSONWITHOUTLEGALPERSONALITY', 'fa-puzzle-piece fa-lg ico-third', 'fa-puzzle-piece fa-fw', 'global.thirds.literals.personwithoutlegalpersonality')
                        ], 'tableThirds');

                    // Format columna 'Notificació principal'
                    var getHtmlContentAdresses = function(data, type, full, meta){
                        var content = "";
                        if(full){
                            var thirdAddress = new ThirdAddress(full, Language.getActiveColumn());
                            content = thirdAddress.getDefaultAddressHtml();
                        }
                        return content;
                    };

                    // Format columna 'Nom': mostrar el nom/cognoms o corporateName, en funció de si és persona física o jurídica
                    var thirdNameColumn = function(data, type, full, meta){
                        var content = "";
                        if(full.thirdType == "PHISICAL"){
                            content = '<strong>'+full.name +'&nbsp;'+full.surename1+((full.surename2)?'&nbsp;'+full.surename2:'')+'</strong>';
                        }else if(full.corporateName){
                            content = '<strong>'+full.corporateName+'</strong>';
                        }
                        return content;
                    };

                    var tdDefThird = new TableData('thirds','Third','./api/thirds/byfilter').setSortName('identificationDocument').setSort([[2, 'asc']]);

                    var tabledataColumnsThird = [
                        
                        DTColumnBuilder.newColumn(null).renderWith(renderRadioColumn).notSortable(),
                        DTColumnBuilder.newColumn('thirdType').withTitle($filter('translate')('global.literals.type')).renderWith(thirdTypeColumn.getRender),
                        DTColumnBuilder.newColumn('identificationDocument').withTitle($filter('translate')('global.literals.identifier')),
                        DTColumnBuilder.newColumn('name').withOption('width', '25%').withTitle($filter('translate')('global.thirds.literals.datatable.col2')).renderWith(thirdNameColumn),
                        DTColumnBuilder.newColumn('addresses').withTitle($filter('translate')('global.thirds.literals.datatable.col4')).renderWith(getHtmlContentAdresses).notSortable()
                    ];

                    var modalName = (thirdBoxType == 'representant') ? globalModals.representationAdd: globalModals.receiverAdd;
                    this.boxDefinition = new BoxDefinition(title_box);
                    this.boxDefinition.addViewAndNewPremissions('view_thirds', 'new_third');
                    if(newButton) {
                        this.boxDefinition.addNew(this.newReceiver, this.completeAddReceiver)
                    }
                    if(searchButton){
                        this.boxDefinition.addSearch('thirds', 'Third', placeholder_search, this.processSearchThird, RestService, undefined, added_filter, additionalFilter, undefined, undefined, renderType);
                    }
                    
                    if(thirdBoxType && thirdBoxType == 'receiver') {
                    	this.boxDefinition.originType = 'dossier';
                    }

                    if(searchAddButton){
                        this.boxDefinition.addSearchAdd((thirdBoxType == 'third') ? undefined : modalName, this.processModalBodyAddThird, this.completeAddReceiver, AnnexaFormlyFactory);
                    }
                    if(advancedSearchButton) {

                        // Definir modal Interesat/Representant
                        if(this.boxDefinition.searchAdd){
                            this.boxDefinition.addSearchAdd((thirdBoxType == 'third') ? undefined : modalName, this.processModalBodyAddThird, this.completeAddReceiver, AnnexaFormlyFactory);
                        }

                        // Definir modal cerca avançada
                        this.boxDefinition.addAdvandedSearch(
                            new BoxAdvancedSearchModalDefinition('thirdbox', advanced_search_title, tfilterThird, tdDefThird, tabledataColumnsThird, this.boxDefinition).changeSize('modal-lg'),
                            AnnexaModalFactory, true, added_filter, additionalFilter, renderType
                        );
                    }
                    if(!newButton && !searchButton && !advancedSearchButton && !searchAddButton){
                        this.boxDefinition.disabled = true;
                    }

                    this.boxDefinition.entityFactory = EntityFactory;

                    return this;
                },
                boxDefinition: null,
                newReceiver: function () {
                    this.entityFactory.showNewThirdModal(this);
                },
                processSearchThird: function(object) {
                    if(object.corporateName) {
                        return object.corporateName + ' (' + object.identificationDocument + ')';
                    } else {
                        return object.name + ' ' + object.surename1 + ((object.surename2)? ' ' +object.surename2 : '' )+ ' (' + object.identificationDocument + ')'
                    }
                },
                completeAddReceiver: function(definition) {
                	var modal_data;
                	if (this.annexaFormly) { // si té definit l'annexaFormly vol dir que ha passat pel modal de destinatari/representant
                		modal_data = this.annexaFormly.model.modal_body;
                	} else if (definition.searchedValue) {
                		modal_data = definition.searchedValue;
                	}
                    
                    var isPrincipal = false;                    
                    if (this.extra && this.extra.content) {
                    	isPrincipal = this.extra.content == 0 ? true : false;
                    } else if (this.extra && this.extra.modal && this.extra.modal.added) {
                    	isPrincipal = this.extra.modal.added == 0 ? true : false;
                    } else if (this.extra && this.extra.modal && this.extra.modal.content) {
                    	isPrincipal = this.extra.modal.content == 0 ? true : false;
                    }
                    
                    var objThird = JSOG.decode(modal_data.object);
                    var boxThird = new BoxThird(objThird, Language.getActiveColumn());
                    var newContent = {};

                    // Si es modal de representant
                    if (modal_data.startValidityPeriod && modal_data.endValidityPeriod){
                    	if(modal_data.representationType && modal_data.representationType == 'representat'){
                    		newContent = {
                    				represented: objThird,
                    				startValidityPeriod: modal_data.startValidityPeriod,
                    				endValidityPeriod: modal_data.endValidityPeriod,
                    				revoked: false
                    		} 
                    	}else{
                    		newContent = {
                    				agent: objThird,
                    				startValidityPeriod: modal_data.startValidityPeriod,
                    				endValidityPeriod: modal_data.endValidityPeriod,
                    				revoked: false
                    		}                    		
                    	}
                    }
                    // si es modal de destinatari
                    else {
                         newContent = {
                            third: objThird,                            
                            interested: modal_data.thirdType == 'interested' ? true : false,
                            representative: modal_data.thirdType == 'representative' ? true : false,
                            principal: isPrincipal,
                            addresses: boxThird.getAdressesHtml(),
                            representated: modal_data.representated,
                            roleInterested: modal_data.roleInterested
                        };
                    }
                    // Completar cerca simple
                    if (this.extra && this.extra.content) {
                        this.extra.content.push(newContent);
                        this.extra.searchedValue = {id: -1, value: ''};
                        if (this.extra.saveSearch) {
                            this.extra.saveSearch(newContent);
                        }
                        this.close();
                    } else if (definition.content){
                    	definition.content.push(newContent);
                        definition.searchedValue = {id: -1, value: ''};
                        if(definition.saveSearch) {
                            definition.saveSearch(newContent);
                        }
                    }
                    
                    // Completar cerca avançada
                    if (this.extra && this.extra.modal && this.extra.modal.boxDefinition) {
                        this.extra.modal.boxDefinition.content.push(newContent);
                        this.extra.searchedValue = {id: -1, value: ''};
                        if (this.extra.modal.boxDefinition.saveSearch) {
                            this.extra.modal.boxDefinition.saveSearch(newContent);
                        }
                        this.extra.closed = true;
                        this.close();
                        this.extra.modal.close();
                    } else if (definition.modal && definition.modal.boxDefinition && definition.modal.boxDefinition.content){
                        definition.modal.boxDefinition.content.push(newContent);
                        definition.searchedValue = {id: -1, value: ''};
                        if(definition.modal.boxDefinition.saveSearch) {
                            definition.modal.boxDefinition.saveSearch(newContent);
                        }
                        definition.modal.close();
                    }
                },
                processModalBodyAddThird: function(selected) {
                    return { third: selected.id, interested: false, representative: false, object: selected.object }
                }
            },
            DocumentBox: {
                initialize: function(title_box, placeholder_search, advanced_search_title, newButton, searchButton, advancedSearchButton, searchAddButton) {

                    /****************************************************/
                    /* Definir funcions per pintar columnes de la taula */
                    /****************************************************/
                    var typeDocumentImg = function (data, type, full, meta) {
                        var content = '';
                        if (full.type) {
                            if (full.type.style) {
                                content = '<circleicon bgcolor="' + full.type.style + ' " title ="' + $filter('annexatranslate')(full.type[lan], $rootScope.app.language) + '">' + full.type.acronym + '</circleicon>';
                            } else {
                                content = '<icon name="' + full.type.acronym + '" title ="' + $filter('annexatranslate')(full.type[lan], $rootScope.app.language) + '">';
                            }
                        }
                        return content;
                    };

                    var linkViewDocument = function (data, type, full, meta) {
                        var code = full.id;
                        if (full.code != null && full.code != undefined) {
                            code = full.code.substr((full.code.indexOf(full.id) - 2), full.id.toString().length + 2);
                        }
                        return code;
                    };

                    var docStatusRender = function(data, type, full, meta) {
                        var content = '';

                        if(DccumentsFactory.statuses && DccumentsFactory.statuses.length > 0) {
                            var status = $linq(DccumentsFactory.statuses).singleOrDefault(undefined, "x => x.id == '" + data + "'");

                            if(status) {
                                content += '<div class="text-center">';
                                content += '    <span class="fa-stack" title="' + $filter('translate')('global.documentState.' + status.name) + '">';
                                content += '        <i class="fa fa-' + status.icon + ' text-' + status.style + ' text-lg fa-stack-1x"></i>';
                                content += '    </span>';
                                content += '</div>';
                            }
                        }

                        return content;
                    }
                    
                    var userColumn = new UserColumn($filter, 'global.literals.user', undefined, true);
                    var dateColumn = new DateColumn($filter, 'global.literals.modifyDate');
                    var tfilterDocument = new TableFilter();
                    var statusesDocumentAux = angular.copy(GlobalDataFactory.documentStatuses);
                    _.forEach(statusesDocumentAux, function(value){
                    	value.name = value.nameLiteral;
                    });
                    /*************************************/
                    /* Definir filtre per cerca avançada */
                    /*************************************/
                    tfilterDocument.addElement('code', 'text', 0, 'global.literals.code');
                    tfilterDocument.addElement('name', 'text', 1, 'global.literals.title');
                    tfilterDocument.addElement('csv', 'text', 2, 'global.documents.new.csv');
                    tfilterDocument.addElement('internalDoc', 'checkbox', 3, 'global.documents.internalDocument').setFilterCallAux(true).setDefaultValue(true);
                    tfilterDocument.addElement('template', 'select', 4, 'global.literals.template', 2, 'Template', true, 'description', false, undefined, lan).setUserChosenFilter(true);
                    tfilterDocument.addElement('section', 'select', 5, 'global.literals.departament', 2, 'Section', true, lan, false, undefined, lan).setUserChosenFilter(true);
                    tfilterDocument.addElement('type', 'select', 6, 'global.literals.type', 2, 'DocumentType', true, lan, false, undefined, lan).setUserChosenFilter(true);
                    tfilterDocument.addElement('origin', 'select', 7, 'global.literals.module', 1, undefined, true, 'name', true, undefined, lan).setUserChosenFilter(true);
                    tfilterDocument.addElement('createUser', 'text', 8, 'global.literals.user').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDocument.addElement('modifyDdate', 'dateRange', 9, 'global.literals.modified').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDocument.addElement('meta', 'text', 10, 'global.literals.meta').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDocument.addElement('dossierNumber', 'text', 11, 'global.literals.numExp').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDocument.addElement('docStatus', 'select',  12, 'global.literals.status', 3, HelperService.addAllSelect(statusesDocumentAux, 'name', lan), true, 'name', true, undefined, lan).setFilterCallAux(true).setUserChosenFilter(true)
                    tfilterDocument.loadLocalData([],undefined, undefined, lan);
                   var tdDefDocument = new TableData('doc','Document','./api/doc/byfilter').setSortName('name').setSort([[3, 'asc']]);

                    /***************************************/
                    /* Definir columnes taula de resultats */
                    /***************************************/
                    var tabledataColumnsDocument = [

                    	// expFilterAdv
                        DTColumnBuilder.newColumn(null).renderWith(checkboxColumn).withTitle(checkboxColumnTitle()).notSortable(),
                        DTColumnBuilder.newColumn('docStatus').withOption('width', '10%').withTitle($filter('translate')('global.literals.status')).renderWith(docStatusRender),
                        DTColumnBuilder.newColumn('type.id').withOption('width', '10%').withTitle($filter('translate')('global.literals.type')).renderWith(typeDocumentImg),
                        DTColumnBuilder.newColumn('code').withOption('width', '10%').withTitle($filter('translate')('global.literals.code')).renderWith(linkViewDocument),
                        DTColumnBuilder.newColumn('name').withOption('width', '45%').withTitle($filter('translate')('global.literals.title')),
                        DTColumnBuilder.newColumn('createUser').withOption('width', '20%').withTitle(userColumn.getTitle()).renderWith(userColumn.getRender),
                        DTColumnBuilder.newColumn('modifyDate').withOption('width', '15%').withTitle(dateColumn.getTitle()).renderWith(dateColumn.getRender),
                    ];

                    this.boxDefinition = new BoxDefinition(title_box, $rootScope.LoggedUser.id);
                    this.boxDefinition.addViewAndNewPremissions('view_documents', 'new_document');

                    // Nou doc
                    if(newButton){
                        this.boxDefinition.addNew(this.newDocument);
                        this.boxDefinition.showNews = true;
                        this.boxDefinition.news = function(){
                            var self = this;
                            var preCloseModalFunction = function () {
                                var count = 0;

                                var docsAux = [];
                                _.forEach(this.wizard.steps[this.wizard.steps.length - 1].annexaFormly.fields[0].data.documents, function (doc) {
                                    if (doc.procState == 'complete') {
                                        docsAux.push(doc.docData);
                                        self.content.push(doc.docData);
                                        count++;
                                    }
                                });
                                if (count > 0 && self.saveSearch) {
                                    self.saveSearch(docsAux);
                                }
                            };
                            DccumentsFactory.openMasiveDocumentModalFolder(preCloseModalFunction, self);
                        }
                    }else{
                        this.boxDefinition.showNews = false;
                    }
                    // Cerca doc
                    if(searchButton){
                        this.boxDefinition.addSearch('doc', 'Document', placeholder_search, this.processSearchDocument, RestService, '{ "code": "@VAL@", "name": "@VAL@"}', undefined, '{ "documentBoxNormalSearch": true}');
                    }
                    // Cerca avançada doc
                    if(advancedSearchButton){

                        if(this.boxDefinition.searchAdd){
                            this.boxDefinition.addSearchAdd(undefined, undefined, this.completeAddDocument, undefined);
                        }

                        // Definir modal cerca avançada
                        this.boxDefinition.addAdvandedSearch(
                            new BoxAdvancedSearchModalDefinition('documentbox', advanced_search_title, tfilterDocument, tdDefDocument, tabledataColumnsDocument, this.boxDefinition).changeSize('modal-lg'),
                            AnnexaModalFactory, true
                        );

                    }
                    // Botó afegir doc
                    if(searchAddButton){
                        this.boxDefinition.addSearchAdd(undefined, undefined, this.completeAddDocument, undefined);
                    }

                    if($rootScope.app.configuration.load_documents_from_scan_folder.value) {
                        this.boxDefinition.showScanner = true;
                        this.boxDefinition.fromScanner = function() {
                            var self = this;
                            var preCloseModalFunction = function () {
                                var count = 0;

                                var docsAux = [];
                                _.forEach(this.wizard.steps[this.wizard.steps.length - 1].annexaFormly.fields[0].data.documents, function (doc) {
                                    if (doc.procState == 'complete') {
                                        docsAux.push(doc.docData);
                                        self.content.push(doc.docData);
                                        count++;
                                    }
                                });
                                if (count > 0 && self.saveSearch) {
                                    self.saveSearch(docsAux);
                                }
                            };
                            DccumentsFactory.openMasiveDocumentModalScan(preCloseModalFunction, self);
                        }
                    } else {
                        this.boxDefinition.showScanner = false;
                    }
                    return this;
                },
                boxDefinition: null,
                newDocument: function () {
                    var self = this;
                    DccumentsFactory.getDocumentLists();
                    var commitModal = function (object) {
                        self.content.push(object);
                        self.searchedValue = { id: -1, value: ''};
                        if(self.saveSearch) {
                            self.saveSearch(object);
                        }
                    };
                    var title = self.titleDocument;
                    if(title){
                        if(title instanceof Object){
                            if(self.titleDocumentProp) {
                                title = title[self.titleDocumentProp];
                            }else{
                                title = '';
                            }
                        }
                    }
                    DccumentsFactory.openNewDocument(undefined, commitModal, self.module, title);
                },
                processSearchDocument: function(object) {
                    return object.name;
                },
                completeAddDocument: function(definition) {
                	var newContent = [];
                	if(definition.searchedValue && definition.searchedValue.object && Array.isArray(definition.searchedValue.object)){
                		_.forEach(definition.searchedValue.object, function(obj){
                			newContent.push(JSOG.decode(obj));
                		});
                	}else{
                		newContent.push(JSOG.decode(definition.searchedValue.object));
                	}

                    // Completar cerca simple
                    if (definition.content){
                    	_.forEach(newContent, function(obj){
                    		definition.content.push(obj);
                    	});
                        definition.searchedValue = {id: -1, value: ''};
                        if(definition.saveSearch) {
                            definition.saveSearch(newContent);
                        }
                    }
                    // Completar cerca avançada
                    if (definition.modal && definition.modal.boxDefinition && definition.modal.boxDefinition.content){
                        _.forEach(newContent, function(obj){
                        	definition.modal.boxDefinition.content.push(obj);
                        });
                        definition.searchedValue = {id: -1, value: ''};
                        if(definition.modal.boxDefinition.saveSearch) {
                            definition.modal.boxDefinition.saveSearch(newContent);
                        }
                        definition.modal.close();
                    }
                	
                }
            },
            RegisterBox: {
                initialize: function(title_box, placeholder_search, advanced_search_title, entryType, newButton, searchButton, advancedSearchButton, searchAddButton, EntityFactory, byEntryType, onlyOne) {

                    var tfilterRegister         = new TableFilter();
                    var tdDefRegister           = new TableData('reg','RegisterEntry','./api/doc/byfilter').setSortName('entryNumber').setSort([[1, 'asc']]);
                    var RegisterDateColumn      = new DateColumn($filter,'global.reg.datatable.registerDate');
                    var PresentationDateColumn  = new DateColumn($filter,'global.literals.presFrom');
                    var EntryChannelColumn      = new DatabaseTranslatedColumn($filter,'global.literals.channel',$rootScope.app.language);
                    var ExtractColumn           = new StrippedColumn($filter,'global.literals.extract');
                    var tabledataColumnsRegister= [];

                    var renderThirds = function(data, type, full, meta){
                        var content = '';
                        var tooltip = '';
                        var principal = '';
                        var numThirds = 0;
                        if(data){
                            if(data.length > 1) {
                                _.forEach(data, function(third){
                                    if(third.principal) {
                                       if(third.third.thirdType != 'PHISICAL') {
                                           principal = third.third.corporateName;
	                                    } else {
	                                        principal = third.third.name + ' ' + third.third.surename1;
	                                        if(third.third.surename2){
	                                            principal += ' ' + third.third.surename2 ;
	                                        }
	                                    }
                                    } else {
            	               			if(numThirds > 0) {
            	               				tooltip += ' | ';
            	               			}
	                                    if(third.third.thirdType != 'PHISICAL') {
	                                           tooltip += third.third.corporateName;
	                                    } else {
	                                           tooltip += third.third.name + ' ' + third.third.surename1;
	                                        if(third.third.surename2){
	                                            tooltip += ' ' + third.third.surename2 ;
	                                        }
	                                    }
                                       numThirds++;
                                    }
                               });
                               content += '<b uib-tooltip="' + tooltip + '" tooltip-placement="top" aria-label="' + tooltip + '"">';
                               content += principal;
                               content += '</b>';
                            } else if(data[0] && data[0].third) {
                                if(data[0].third.thirdType != 'PHISICAL') {
                                    content = data[0].third.corporateName;
                                } else {
                                    content = data[0].third.name + ' ' + data[0].third.surename1;
                                    if(data[0].third.surename2){
                                        content += ' ' + data[0].third.surename2 ;
                                    }
                                }
                            }
                        }
                        return content;
                    }
                    
                    tfilterRegister.addElement('entryNumber', 'text', 0, 'global.literals.number');
                    tfilterRegister.addElement('createdNumber', 'text', 1, 'global.literals.numExp').setUserChosenFilter(true); //Todo: Canviar pel num expedient quan estigui
                    tfilterRegister.addElement('registerEntryChannel', 'select', 2, 'global.literals.channel', 2, 'RegisterEntryChannel', true, 'name', true, undefined, lan).setUserChosenFilter(true);
                    tfilterRegister.addElement('extract', 'text', 3, 'global.literals.extract');
                    tfilterRegister.addElement('dataReg', 'dateRange', 4, 'global.literals.regFrom').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterRegister.addElement('entryDocumentType', 'select', 6, 'global.literals.docType', 2, 'RegisterEntryDocumentType', true, 'name', true, undefined, lan).setUserChosenFilter(true);
                    tfilterRegister.addElement('entryClassification', 'select', 7, 'global.literals.classification', 2, 'RegisterEntryClassification', true, 'name', false, undefined, lan).setUserChosenFilter(true);
                    tfilterRegister.addElement('documentNumber', 'text', 9, 'global.literals.documentNumber').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterRegister.addElement('representant', 'text', 10, 'global.literals.representant').setFilterCallAux(true).setUserChosenFilter(true);

                    switch (entryType){

                        case "INPUT":
                            tfilterRegister.loadLocalData([], undefined, 'INPUT', lan);
                            tfilterRegister.addElement('dataPres', 'dateRange', 5, 'global.literals.presFrom').setFilterCallAux(true).setUserChosenFilter(true);
                            tfilterRegister.addElement('diligenceType', 'select', 8, 'global.literals.diligenceType', 2, 'RegisterDiligenceType', true, 'name', false, undefined, lan).setFilterCallAux(true).setUserChosenFilter(true);
                            tabledataColumnsRegister = [
                                
                            	// expFilterAdv
                                DTColumnBuilder.newColumn(null).renderWith(checkboxColumn).withTitle(checkboxColumnTitle()).notSortable(),
                            	
                                DTColumnBuilder.newColumn('entryNumber').withOption('width', '110px').withTitle($filter('translate')('global.literals.number')),
                                DTColumnBuilder.newColumn('registerDate').withOption('width', '90px').withTitle(RegisterDateColumn.getTitle()).renderWith(RegisterDateColumn.getRender),
                                DTColumnBuilder.newColumn('presentationDate').withOption('width', '90px').withTitle(PresentationDateColumn.getTitle()).renderWith(PresentationDateColumn.getRender),
                                DTColumnBuilder.newColumn('registerEntryChannel.' + lan).withOption('width', '60px').withTitle(EntryChannelColumn.getTitle()).renderWith(EntryChannelColumn.getRender),
                                DTColumnBuilder.newColumn('extract').withTitle(ExtractColumn.getTitle()).renderWith(ExtractColumn.getRender).notSortable(),
                                DTColumnBuilder.newColumn('registerEntryOffice.' + lan).withTitle($filter('translate')('global.literals.office')),
                                DTColumnBuilder.newColumn('thirds').withTitle($filter('translate')('global.literals.thirds')).renderWith(renderThirds).notSortable()                                
                            ];
                            break;

                        case "OUTPUT":
                            tfilterRegister.loadLocalData([], undefined, 'OUTPUT', lan);
                            tfilterRegister.addElement('managed', 'select', 11, 'global.literals.state',3, HelperService.addAllSelect(angular.copy(statesOutput),'name', lan), true, 'name', false).setFilterCallAux(true).setUserChosenFilter(true);
                            tabledataColumnsRegister = [
   
                            	// expFilterAdv
                                DTColumnBuilder.newColumn(null).renderWith(checkboxColumn).withTitle(checkboxColumnTitle()).notSortable(),
                            	
                                DTColumnBuilder.newColumn('entryNumber').withOption('width', '110px').withTitle($filter('translate')('global.literals.number')),
                                DTColumnBuilder.newColumn('registerDate').withOption('width', '90px').withTitle(RegisterDateColumn.getTitle()).renderWith(RegisterDateColumn.getRender),
                                DTColumnBuilder.newColumn('registerEntryChannel.' + lan).withOption('width', '60px').withTitle(EntryChannelColumn.getTitle()).renderWith(EntryChannelColumn.getRender),
                                DTColumnBuilder.newColumn('extract').withTitle(ExtractColumn.getTitle()).renderWith(ExtractColumn.getRender).notSortable(),
                                DTColumnBuilder.newColumn('registerEntryOffice.' + lan).withTitle($filter('translate')('global.literals.office')),
                                DTColumnBuilder.newColumn('thirds').withTitle($filter('translate')('global.literals.thirds')).renderWith(renderThirds).notSortable()
                            ];
                            break;

                        default:
                        	tfilterRegister.loadLocalData([], undefined, undefined, lan);
	                        tabledataColumnsRegister = [];
	                        if(onlyOne){
	                        	tabledataColumnsRegister.push(DTColumnBuilder.newColumn(null).renderWith(renderRadioColumn).notSortable());
	                    	}else{
	                    		tabledataColumnsRegister.push(DTColumnBuilder.newColumn(null).renderWith(checkboxColumn).withTitle(checkboxColumnTitle()).notSortable());
	                    	}
	                        tabledataColumnsRegister.push(DTColumnBuilder.newColumn('entryNumber').withOption('width', '110px').withTitle($filter('translate')('global.literals.number')));
	                        tabledataColumnsRegister.push(DTColumnBuilder.newColumn('registerDate').withOption('width', '90px').withTitle(RegisterDateColumn.getTitle()).renderWith(RegisterDateColumn.getRender));
                    		tabledataColumnsRegister.push(DTColumnBuilder.newColumn('presentationDate').withOption('width', '90px').withTitle(PresentationDateColumn.getTitle()).renderWith(PresentationDateColumn.getRender));
                    		tabledataColumnsRegister.push(DTColumnBuilder.newColumn('registerEntryChannel.' + lan).withOption('width', '60px').withTitle(EntryChannelColumn.getTitle()).renderWith(EntryChannelColumn.getRender));
                    		tabledataColumnsRegister.push(DTColumnBuilder.newColumn('extract').withTitle(ExtractColumn.getTitle()).renderWith(ExtractColumn.getRender).notSortable());
                    		tabledataColumnsRegister.push(DTColumnBuilder.newColumn('registerEntryOffice.' + lan).withTitle($filter('translate')('global.literals.office')));
                    		tabledataColumnsRegister.push(DTColumnBuilder.newColumn('thirds').withTitle($filter('translate')('global.literals.thirds')).renderWith(renderThirds).notSortable());                                
                            break;
                    }

                    this.boxDefinition = new BoxDefinition(title_box, $rootScope.LoggedUser.id, entryType);
                    if(entryType == 'INPUT'){
                        this.boxDefinition.addViewAndNewPremissions(['view_input_register', 'diligence_input_register','view_all_input_register','view_all_diligences'], 'new_input_entry_register');
                    }else if(entryType == 'OUTPUT'){
                        this.boxDefinition.addViewAndNewPremissions('view_output_register', 'new_output_entry_register','view_all_output_register');
                    }else{
                    	this.boxDefinition.addViewAndNewPremissions(['view_input_register', 'diligence_input_register','view_all_input_register','view_all_diligences', 'view_output_register','view_all_output_register'], ['new_input_entry_register', 'new_output_entry_register']);
                    }

                    if(newButton){
                        this.boxDefinition.addNew(this.newRegister)
                    }
                    if(searchButton){
                        this.boxDefinition.addSearch('reg', 'RegisterEntry', placeholder_search, this.processSearchRegister, RestService, undefined, undefined, undefined, byEntryType, entryType)
                    }
                    if(advancedSearchButton){

                        if(this.boxDefinition.searchAdd){
                            this.boxDefinition.addSearchAdd(undefined, undefined, this.completeAddRegister, undefined);
                        }

                        // Definir modal cerca avançada
                        this.boxDefinition.addAdvandedSearch(
                            new BoxAdvancedSearchModalDefinition('registerbox', advanced_search_title, tfilterRegister, tdDefRegister, tabledataColumnsRegister, this.boxDefinition, {entryType: entryType}).changeSize('modal-lg'),
                            AnnexaModalFactory, true
                        );

                    }
                    if(searchAddButton){
                        this.boxDefinition.addSearchAdd(undefined, undefined, this.completeAddRegister, undefined);
                    }
                    if(!newButton && !searchButton && !advancedSearchButton && !searchAddButton){
                        this.boxDefinition.disabled = true;
                    }

                    this.boxDefinition.entityFactory = EntityFactory;

                    return this;
                },
                boxDefinition: null,
                newRegister: function () {
                    RegFactory.newRegisterEntryModal(this.originType);
                },
                processSearchRegister: function(object) {
                    var value = '';
                    if(object.entryType == 'INPUT'){
                        value = '<i class="fa fa-sign-in"></i> ';
                    }else{
                        value = '<i class="fa fa-flip-horizontal fa-sign-out"></i> ';
                    }
                    value = value + object.entryNumber + " ";
                    if(object.extract){
                        value = value +object.extract;
                    }
                    return value;
                },
                completeAddRegister: function(definition) {

                	var newContent = [];
                	if(definition.searchedValue && definition.searchedValue.object && Array.isArray(definition.searchedValue.object)){
                		_.forEach(definition.searchedValue.object, function(obj){
                			newContent.push(JSOG.decode(obj));
                		});
                	}else{
                		newContent.push(JSOG.decode(definition.searchedValue.object));
                	}

                    // Completar cerca simple
                    if (definition.content){
                    	_.forEach(newContent, function(obj){
                    		definition.content.push(obj);
                    	});
                        definition.searchedValue = {id: -1, value: ''};
                        if(definition.saveSearch) {
                            definition.saveSearch(newContent);
                        }
                    }
                    // Completar cerca avançada
                    if (definition.modal && definition.modal.boxDefinition && definition.modal.boxDefinition.content){
                        newContent.dossierTransaction = definition.modal.boxDefinition.transaction;
                        _.forEach(newContent, function(obj){
                        	definition.modal.boxDefinition.content.push(obj);
                        });
                        definition.searchedValue = {id: -1, value: ''};
                        if(definition.modal.boxDefinition.saveSearch) {
                            definition.modal.boxDefinition.saveSearch(newContent);
                        }
                        definition.modal.close();
                    }
                	
                    definition.searchedValue = { id: -1, value: ''};

                }
            },
            DocumentChildBox: {
                initialize: function(title_box, placeholder_search, advanced_search_title, newButton, searchButton, advancedSearchButton, searchAddButton) {

                    /****************************************************/
                    /* Definir funcions per pintar columnes de la taula */
                    /****************************************************/
                    var typeDocumentImg = function (data, type, full, meta) {
                        var content = '';
                        if (full.type) {
                            if (full.type.style) {
                                content = '<circleicon bgcolor="' + full.type.style + ' " title ="' + $filter('annexatranslate')(full.type[lan], $rootScope.app.language) + '">' + full.type.acronym + '</circleicon>';
                            } else {
                                content = '<icon name="' + full.type.acronym + '" title ="' + $filter('annexatranslate')(full.type[lan], $rootScope.app.language) + '">';
                            }
                        }
                        return content;
                    };

                    var linkViewDocument = function (data, type, full, meta) {
                        var code = full.id;
                        if (full.code != null && full.code != undefined) {
                            code = full.code.substr((full.code.indexOf(full.id) - 2), full.id.toString().length + 2);
                        }
                        return code;
                    };

                    var docStatusRender = function(data, type, full, meta) {
                        var content = '';

                        if(DccumentsFactory.statuses && DccumentsFactory.statuses.length > 0) {
                            var status = $linq(DccumentsFactory.statuses).singleOrDefault(undefined, "x => x.id == '" + data + "'");

                            if(status) {
                                content += '<div class="text-center">';
                                content += '    <span class="fa-stack" title="' + $filter('translate')('global.documentState.' + status.name) + '">';
                                content += '        <i class="fa fa-' + status.icon + ' text-' + status.style + ' text-lg fa-stack-1x"></i>';
                                content += '    </span>';
                                content += '</div>';
                            }
                        }

                        return content;
                    }
                    
                    var userColumn = new UserColumn($filter, 'global.literals.user', undefined, true);
                    var dateColumn = new DateColumn($filter, 'global.literals.modifyDate');
                    var tfilterDocument = new TableFilter();
                    var statusesDocumentAux = angular.copy(GlobalDataFactory.documentStatuses);
                    _.forEach(statusesDocumentAux, function(value){
                    	value.name = value.nameLiteral;
                    });
                    /*************************************/
                    /* Definir filtre per cerca avançada */
                    /*************************************/
                    tfilterDocument.addElement('code', 'text', 0, 'global.literals.code');
                    tfilterDocument.addElement('name', 'text', 1, 'global.literals.title');
                    tfilterDocument.addElement('csv', 'text', 2, 'global.documents.new.csv');
                    tfilterDocument.addElement('internalDoc', 'checkbox', 3, 'global.documents.internalDocument').setFilterCallAux(true).setDefaultValue(true);
                    tfilterDocument.addElement('template', 'select', 4, 'global.literals.template', 2, 'Template', true, 'description', false, undefined, lan).setUserChosenFilter(true);
                    tfilterDocument.addElement('section', 'select', 5, 'global.literals.departament', 2, 'Section', true, lan, false, undefined, lan).setUserChosenFilter(true);
                    tfilterDocument.addElement('type', 'select', 6, 'global.literals.type', 2, 'DocumentType', true, lan, false, undefined, lan).setUserChosenFilter(true);
                    tfilterDocument.addElement('origin', 'select', 7, 'global.literals.module', 1, undefined, true, 'name', true, undefined, lan).setUserChosenFilter(true);
                    tfilterDocument.addElement('createUser', 'text', 8, 'global.literals.user').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDocument.addElement('modifyDdate', 'dateRange', 9, 'global.literals.modified').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDocument.addElement('meta', 'text', 10, 'global.literals.meta').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDocument.addElement('dossierNumber', 'text', 11, 'global.literals.numExp').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDocument.addElement('docStatus', 'select',  12, 'global.literals.status', 3, HelperService.addAllSelect(statusesDocumentAux, 'name', lan), true, 'name', true, undefined, lan).setFilterCallAux(true).setUserChosenFilter(true)
                    tfilterDocument.loadLocalData([],undefined, undefined, lan);
                    var tdDefDocument = new TableData('doc','Document','./api/doc/byfilter').setSortName('name').setSort([[3, 'asc']]);

                    /***************************************/
                    /* Definir columnes taula de resultats */
                    /***************************************/
                    var tabledataColumnsDocument = [
                        
                    	// expFilterAdv
                        DTColumnBuilder.newColumn(null).renderWith(checkboxColumn).withTitle(checkboxColumnTitle()).notSortable(),
                        DTColumnBuilder.newColumn('docStatus').withOption('width', '10%').withTitle($filter('translate')('global.literals.status')).renderWith(docStatusRender),
                        DTColumnBuilder.newColumn('type.id').withOption('width', '10%').withTitle($filter('translate')('global.literals.type')).renderWith(typeDocumentImg),
                        DTColumnBuilder.newColumn('code').withOption('width', '10%').withTitle($filter('translate')('global.literals.code')).renderWith(linkViewDocument),
                        DTColumnBuilder.newColumn('name').withOption('width', '45%').withTitle($filter('translate')('global.literals.title')),
                        DTColumnBuilder.newColumn('createUser').withOption('width', '20%').withTitle(userColumn.getTitle()).renderWith(userColumn.getRender),
                        DTColumnBuilder.newColumn('modifyDate').withOption('width', '15%').withTitle(dateColumn.getTitle()).renderWith(dateColumn.getRender)
                    ];

                    this.boxDefinition = new BoxDefinition(title_box, $rootScope.LoggedUser.id);
                    this.boxDefinition.addViewAndNewPremissions('view_documents', 'new_document');

                    // Nou doc
                    if(newButton){
                        this.boxDefinition.addNew(this.newDocument)
                        this.boxDefinition.showNews = true;
                        this.boxDefinition.showNewInteroperable = false;
                        if($rootScope.app.configuration.add_document_via_oberta != null && TramFactory.Dossier){
                        	this.boxDefinition.showNewInteroperable = $rootScope.app.configuration.add_document_via_oberta.value;
                        }
                        this.boxDefinition.news = function(){
                            var self = this;
                            var preCloseModalFunction = function() {
                                var count = 0;
                                var docsToAddAux = [];
                                _.forEach(this.wizard.steps[this.wizard.steps.length - 1].annexaFormly.fields[0].data.documents, function (doc) {
                                    if(doc.procState == 'complete') {
                                        var newContent = {
                                            dossierTransaction: self.transaction,
                                            document: doc.docData,
                                            relationType: 'DOCUMENT',
                                            publicDocument: false,
                                            paperBin: false
                                        };
                                        self.content.push(newContent);
                                        docsToAddAux.push(newContent)
                                        count ++;
                                    }
                                });
                                if(count > 0 && self.saveSearch) {
                                    self.saveSearch(docsToAddAux);
                                }else{
                                	$rootScope.$broadcast('addRemovedObjectEvent', {addRemoveObject: true});
                                    $rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: self.content, relationType: 'DOCUMENT', transactionId: ((self.transaction)?self.transaction.id:undefined) });
                                }
                            }
                            DccumentsFactory.openMasiveDocumentModalFolder(preCloseModalFunction, self, self.archiveClassification, JSOG.encode(self.transaction));
                        }
                        if(this.boxDefinition.showNewInteroperable) {
                        	this.boxDefinition.newInteroperable = function(){
                                var self = this;
                                var commitModal = function(documents) {
                                	var count = 0;
                                	_.forEach(documents, function (doc) {
                                        var newContent = {
                                            dossierTransaction: self.transaction,
                                            document: doc,
                                            relationType: 'DOCUMENT',
                                            publicDocument: false,
                                            paperBin: false
                                        };
                                        self.content.push(newContent);
                                        count ++;
                                    });
                                	if(count > 0 && self.saveSearch) {
                                        self.saveSearch();
                                    }else{
                                    	$rootScope.$broadcast('addRemovedObjectEvent', {addRemoveObject: true});
                                        $rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: self.content, relationType: 'DOCUMENT', transactionId: ((self.transaction)?self.transaction.id:undefined) });
                                    }
                                }
                                DccumentsFactory.openNewInteroperbleModal(self, TramFactory.Dossier.thirds, commitModal, "EXPED", JSOG.encode(self.transaction));
                            }
                        }
                    }else{
                        this.boxDefinition.showNews = false;
                    }
                    // Cerca doc
                    if(searchButton){
                        this.boxDefinition.addSearch('doc', 'Document', placeholder_search, this.processSearchDocument, RestService, '{ "code": "@VAL@", "name": "@VAL@"}', "x => x.document.id",{ "documentBoxNormalSearch": true});
                    }
                    // Cerca avançada doc
                    if(advancedSearchButton){

                        if(this.boxDefinition.searchAdd){
                            this.boxDefinition.addSearchAdd(undefined, undefined, this.completeAddDocument, undefined);
                        }

                        // Definir modal cerca avançada
                        this.boxDefinition.addAdvandedSearch(
                            new BoxAdvancedSearchModalDefinition('documentbox', advanced_search_title, tfilterDocument, tdDefDocument, tabledataColumnsDocument, this.boxDefinition).changeSize('modal-lg'),
                            AnnexaModalFactory, true, "x => x.document.id"
                        );

                    }
                    // Botó afegir doc
                    if(searchAddButton){
                        this.boxDefinition.addSearchAdd(undefined, undefined, this.completeAddDocument, undefined);
                    }

                    if($rootScope.app.configuration.load_documents_from_scan_folder.value) {
                        this.boxDefinition.showScanner = true;
                        this.boxDefinition.fromScanner = function() {
                            var self = this;
                            var preCloseModalFunction = function() {
                                var count = 0;
                                var docsToAddAux = [];
                                _.forEach(this.wizard.steps[this.wizard.steps.length - 1].annexaFormly.fields[0].data.documents, function (doc) {
                                    if(doc.procState == 'complete') {
                                        var newContent = {
                                            dossierTransaction: self.transaction,
                                            document:doc.docData,
                                            relationType: 'DOCUMENT',
                                            publicDocument: false,
                                            paperBin: false
                                        };
                                        self.content.push(newContent);
                                        docsToAddAux.push(newContent)
                                        count ++;
                                    }
                                });
                                if(count > 0 && self.saveSearch) {
                                    self.saveSearch(docsToAddAux);
                                }else{
                                	$rootScope.$broadcast('addRemovedObjectEvent', {addRemoveObject: true});
                                    $rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: self.content, relationType: 'DOCUMENT', transactionId: ((self.transaction)?self.transaction.id:undefined) });
                                }
                            }
                            DccumentsFactory.openMasiveDocumentModalScan(preCloseModalFunction, self, self.archiveClassification, JSOG.encode(self.transaction));
                        }
                    } else {
                        this.boxDefinition.showScanner = false;
                    }

                    return this;
                },
                boxDefinition: null,
                newDocument: function () {
                    var self = this;
                    DccumentsFactory.getDocumentLists();
                    var commitModal = function (object) {
                        var doc = {
                            dossierTransaction: self.transaction,
                            document: object,
                            relationType: 'DOCUMENT',
                            publicDocument: false,
                            paperBin: false
                        }
                        self.content.push(doc);
                        self.searchedValue = { id: -1, value: ''};
                        if(self.saveSearch) {
                            self.saveSearch(doc);
                        }else{
                        	$rootScope.$broadcast('addRemovedObjectEvent', {addRemoveObject: true});
                            $rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: self.content, relationType: 'DOCUMENT', transactionId: ((self.transaction)?self.transaction.id:undefined) });
                        }
                    };

                    if(self && self.transaction) {
                    	var extra = {
                            	transactionType: self.transaction.transactionType,
                            	proposalSubtype: self.transaction.proposalSubtype,
                            	processProfiles: self.transaction.processProfiles
                            }
                    }
                    
                    DccumentsFactory.openNewDocument(undefined, commitModal, self.module, self.titleDocument, undefined, self.archiveClassification, undefined, extra);
                },
                processSearchDocument: function(object) {
                    return object.name;
                },
                completeAddDocument: function(definition) {
                	var newContent = [];
                	if(definition.searchedValue && definition.searchedValue.object && Array.isArray(definition.searchedValue.object)){
                		_.forEach(definition.searchedValue.object, function(obj){
                			newContent.push({
                                dossierTransaction:definition.transaction,
                                document:JSOG.decode(obj),
                                relationType: 'DOCUMENT',
                                publicDocument: false,
                                paperBin: false
                            });
                		});
                	}else{
                		newContent.push({
                            dossierTransaction:definition.transaction,
                            document:JSOG.decode(definition.searchedValue.object),
                            relationType: 'DOCUMENT',
                            publicDocument: false,
                            paperBin: false
                        });
                	}

                    // Completar cerca simple
                    if (definition.content){
                    	_.forEach(newContent, function(obj){
                    		definition.content.push(obj);
                    	});
                        definition.searchedValue = {id: -1, value: ''};
                        if(definition.saveSearch) {
                            definition.saveSearch(newContent);
                        }else{
                        	$rootScope.$broadcast('addRemovedObjectEvent', {addRemoveObject: true});
                            $rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: definition.content, relationType: 'DOCUMENT', transactionId: ((definition.transaction)?definition.transaction.id:undefined) });
                        }
                    }
                    // Completar cerca avançada
                    if (definition.modal && definition.modal.boxDefinition && definition.modal.boxDefinition.content){
                        newContent.dossierTransaction = definition.modal.boxDefinition.transaction;
                        _.forEach(newContent, function(obj){
                        	definition.modal.boxDefinition.content.push(obj);
                        });
                        definition.searchedValue = {id: -1, value: ''};
                        if(definition.modal.boxDefinition.saveSearch) {
                            definition.modal.boxDefinition.saveSearch(newContent);
                        }else{
                        	$rootScope.$broadcast('addRemovedObjectEvent', {addRemoveObject: true});
                            $rootScope.$broadcast('annexaBoxDocumentsTramUpdateCounter', { content: definition.modal.boxDefinition.content, relationType: 'DOCUMENT', transactionId: ((definition.modal.boxDefinition.transaction)?definition.modal.boxDefinition.transaction.id:undefined) });
                        }
                        definition.modal.close();
                    }
                }
            },
            DossierTransactionBox: {
                initialize: function (title_box, placeholder_search, advanced_search_title, newButton, searchButton, advancedSearchButton, lan) {

                    var DaysColumn = new RemaingDaysColumn($filter, 'global.literals.days', HelperService);
                    var ProcedureColumn = new DatabaseTranslatedColumn($filter, 'global.literals.procedure', lan);
                    var TransactionColumn = new DatabaseTranslatedColumn($filter, 'global.literals.transaction', lan);
                    var StDateColumn = new StartDateColumn($filter, 'global.delegate.startDate');
                    var CreatorUserColumn = new UserColumn($filter, 'global.literals.creator');
                    var RelatedColumn = new RelatedDossiersColumn();

                    var tfilterDossier = new TableFilter();
                    var tdDefDossier = new TableData('tram', 'DossierTransaction', './api/tram/byfilter').setSortName('maxDays').setSort([[1, 'asc']]);

                    /*************************************/
                    /* Definir filtre per cerca avançada */
                    /*************************************/
                    tfilterDossier.addElement('dossierNumber', 'text', 0, 'global.literals.code').setFilterCallAux(true);
                    tfilterDossier.addElement('family', 'select', 1, 'global.literals.family', 2, 'FamilyProcedure', true, lan, false, undefined, lan).setFilterCallAux(true);
                    tfilterDossier.addElement('procedure', 'text', 2, 'global.literals.procedure').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('subject', 'text', 3, 'global.literals.subject').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('thirds', 'text', 4, 'global.literals.thirdsInt').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('thirds_identification', 'text', 5, 'global.literals.thirds_identification').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement(lan, 'text', 6, 'global.literals.transaction').setUserChosenFilter(true);
                    tfilterDossier.addElement('custom_fields_search', 'text', 7, 'global.literals.customFields').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('dates', 'dateRange', 8, 'global.delegate.startDate').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('creator', 'text', 9, 'global.literals.creator').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('register_entry', 'text', 10, 'global.literals.register_entry_INI').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.loadLocalData([], undefined, undefined, lan);

                    var tabledataColumnsDossier = [
                        DTColumnBuilder.newColumn(null).renderWith(function (data, type, full, meta) {

                        	// expFilterAdv
                            return '<input type="checkbox" id="'+full.id+'" name="selected" data-ng-model="rowSelected['+full.id+']" data-ng-value="'+full.id+'" data-ng-change="selectRow('+data.id+')" aria-label="{{ \'global.literals.select\' | translate }}">';
                        	
                        }).withTitle(checkboxColumnTitle()).notSortable(),
                        DTColumnBuilder.newColumn('dossier.dossierNumber').withTitle($filter('translate')('global.literals.code')),
                        DTColumnBuilder.newColumn('dossier.procedure.' + ProcedureColumn.getColumn()).withTitle(ProcedureColumn.getTitle()),
                        DTColumnBuilder.newColumn(TransactionColumn.getColumn()).withTitle(TransactionColumn.getTitle()),
                        DTColumnBuilder.newColumn('dossier.subject').withTitle($filter('translate')('global.literals.dossier'))
                    ];

                    this.boxDefinition = new BoxDefinition(title_box, $rootScope.LoggedUser.id);
                    this.boxDefinition.addViewAndNewPremissions('view_dossier', 'start_dossier');
                    if (newButton) {
                        this.boxDefinition.addNew(this.newDossier, this.completeNewDossier);
                    }
                    if (searchButton) {
                        this.boxDefinition.addSearch('tram', 'DossierTransaction', placeholder_search, this.processSearchDossier, RestService, '{ "language1":"@VAL@", "dossier":{"subject": "@VAL@", "dossierNumber": "@VAL@"}}',undefined,{"addSearchValue":true, "langColumn":lan});
                    }
                    if (advancedSearchButton) {

                        if (this.boxDefinition.searchAdd) {
                            this.boxDefinition.addSearchAdd(undefined, undefined, this.completeNewDossier, undefined);
                        }

                        // Definir modal cerca avançada
                        this.boxDefinition.addAdvandedSearch(
                            new BoxAdvancedSearchModalDefinition('dossiertransactionbox', advanced_search_title, tfilterDossier, tdDefDossier, tabledataColumnsDossier, this.boxDefinition).changeSize('modal-lg'),
                            AnnexaModalFactory, true
                        );

                    }

                    if (!newButton && !searchButton && !advancedSearchButton) {
                        this.boxDefinition.disabled = true;
                    }

                    return this;
                },
                boxDefinition: null,
                newDossier: function () {
                    alert("Nou expedient");
                },
                completeNewDossier: function (definition) {
                	var newContent = [];
                	if(definition.searchedValue && definition.searchedValue.object && Array.isArray(definition.searchedValue.object)){
                		_.forEach(definition.searchedValue.object, function(obj){
                			newContent.push(JSOG.decode(obj));
                		});
                	}else{
                		newContent.push(JSOG.decode(definition.searchedValue.object));
                	}

                    // Completar cerca simple
                    if (definition.content){
                    	_.forEach(newContent, function(obj){
                    		definition.content.push(obj);
                    	});
                        if(definition.saveSearch) {
                            definition.saveSearch(newContent);
                        }
                    }
                    // Completar cerca avançada
                    if (definition.modal && definition.modal.boxDefinition && definition.modal.boxDefinition.content){
                        _.forEach(newContent, function(obj){
                        	definition.modal.boxDefinition.content.push(obj);
                        });
                        if(definition.modal.boxDefinition.saveSearch) {
                            definition.modal.boxDefinition.saveSearch(newContent);
                        }
                        definition.modal.close();
                        if (definition.close) {
                            definition.close();
                        }
                    }
                	
                    definition.searchedValue = {id: -1, value: ''};
                },
                processSearchDossier: function (object) {
                    var languageColumn = Language.getActiveColumn();
                    var profiles = '';
                    if (object.dossier.subject) {
                        profiles += object.dossier.subject + ", ";
                    }
                    profiles += object.transactionType[languageColumn];
                    angular.forEach(object.processProfiles, function (value) {
                        profiles += ", " + value.profile[languageColumn];
                    });
                    return object.dossier.dossierNumber + " (" + profiles + ")";
                }
            }
        }
    }]);
